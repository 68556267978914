import storeBuilder from '@/store/storeBuilder';
import { AuthState } from './states';
import { User } from './types';

const b = storeBuilder.module<AuthState>('auth', new AuthState());

// getters
const authUser = b.read((state) => state.authUser, 'authUser');

export const getters = {
  get authUser() {
    return authUser();
  },
};

// mutations
function setUser(state: AuthState, user: User) {
  state.authUser = user;
}

export const mutations = {
  setUser: b.commit(setUser),
};
