const messages = {
  header: 'SAIO – Success stories',
  headerContent1:
    'Dzięki SAIO przez lata zgromadziliśmy doświadczenie i wiedzę w zakresie robotyzacji z wykorzystaniem technologii AI, aby dostarczać naszym klientom kompleksowe rozwiązania dostosowane do ich potrzeb.',
  headerContent2:
    'Nieustannie współpracujemy z naszymi klientami, aby zapewnić, że SAIO może stale ulepszać procesy biznesowe w ciągle zmieniającym się cyfrowym świecie.',
  implementationsEmpty: 'Brak wdrożen',
  readMore: 'Przeczytaj więcej',
  clientInfoHeader: 'Klient',
  profileInfoHeader: 'Profil',
  dateInfoHeader: 'Wdrożenie',
  challengeHeader: 'Wyzwanie',
  solutionHeader: 'Rozwiązanie',
  processHeader: 'Proces',
  benefitHeader: 'Benefity',
  clientReviewHeader: 'Opinia klienta',
  otherImplementations: 'Inne realizacje:',
};

export default messages;
