
import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';

@Component({})
export default class Post extends Vue {
  @Prop({ required: true }) id!: number;

  @Prop({ required: true }) title!: string;

  @Prop({ required: true }) date!: Date;

  @Prop({ required: true }) img!: string;

  @Prop({ required: true }) isMain!: boolean;

  @Prop({ required: true }) isOutsideNewsroom!: boolean;

  @Prop({ required: false }) useBlackFont: boolean | undefined;

  @Prop({ required: false }) additionalClass?: string;

  @Prop({ required: false }) redirectionPageName?: string;

  get postClasses() {
    const localClasses: string[] = this.additionalClass ? ['post', this.additionalClass] : ['post'];
    return this.isMain ? localClasses : [...localClasses, 'p-3', 'col-md-6'];
  }

  get articleHeaderClasses() {
    const colorClass = this.useBlackFont
      ? 'article-header-color-black'
      : 'article-header-color-white';
    const localClasses: string[] = ['article-header', colorClass];
    const mainClass = this.isMain ? ['article-header-main'] : [];
    const outsideNewsroomClass = this.isOutsideNewsroom ? ['article-header-small'] : [];
    return ['article-header', ...localClasses, ...mainClass, ...outsideNewsroomClass];
  }

  get postBorderClasses() {
    const colorClass = this.useBlackFont
      ? 'post-border-color-dark-gray'
      : 'post-border-color-light-gray';
    return ['post-border', colorClass];
  }

  get dateClasses() {
    const colorClass = this.useBlackFont ? 'date-color-dark-gray' : 'date-color-light-gray';
    return ['date', colorClass];
  }

  @Emit('onIdChanged')
  public onClick() {
    if (!this.redirectionPageName) {
      return this.id;
    }

    this.$router.push({ name: this.redirectionPageName, params: { id: this.id.toString() } });
    return this.id;
  }
}
