import Vue from 'vue';
import Fragment from 'vue-fragment';
import App from './App.vue';
import './registerServiceWorker';

import router from './router';
import store from './store';
import i18n from './localization';

Vue.use(Fragment.Plugin);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
