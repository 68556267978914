const messages = {
  header: 'Technology',
  description1:
    'In almost every company, there are many repetitive and monotonous processes that employees handle manually. Does it always have to be this way?',
  description2: 'Of course not!',
  appendix: 'Want to find out what Robotic Process Automation is?',

  technologyHeader: 'In robots we trust',

  statisticNumber: '90',
  statisticHeader: '90% of large organisations will implement robotic solutions by 2022. Why?',
  statisticDescription:
    'These organisations are looking to digitally enhance key business processes. Automation can help them do this as it provides resilience and scalability to processes. It also improves the productivity of employees who, instead of performing repetitive and monotonous tasks, can focus on more creative work.',
  statisticAppendix: 'GARTNER 2020',

  options: [
    {
      header: 'Better business results',
      description:
        'Give employees more challenging tasks. Gain more efficiency and optimise costs.',
    },
    {
      header: 'Less risk of human error',
      description:
        'Monotonous work causes stress and frustration. Robots are not affected by them.',
    },
    {
      header: 'Cost reduction',
      description: "You don't need to hire and train new staff to handle growing volumes.",
    },
    {
      header: 'Culture of innovation',
      description: 'Allow employees to build digital competencies and put them into action.',
    },
    {
      header: 'Stability and security',
      description:
        'Robotic processes run smoothly. It’s important now,when most companies operate remotely.',
    },
    {
      header: 'Resistance to volume peaks',
      description:
        'Whether you have 10 or 100 reports to prepare – the process always runs smoothly.',
    },
    {
      header: 'Satisfied employees',
      description: 'Delegate repetitive tasks to robots. Letemployees develop their skills.',
    },
  ],
};

export default messages;
