const messages = {
  mainSite: 'SAIO - digitalizacja procesów',

  headerTopSayHiTo: 'SAY HI TO ',
  headerTopS: 'S',
  headerTopO: 'O',
  headerTopAI: 'AI',
  headerBottom: '',
  headerDescription:
    'Za 10 lat roboty będą wykonywać za nas 90% czasochłonnych zadań. Przenieś się do przyszłości - zacznij robotyzować procesy już teraz.',
  leaveContactButton: 'Zostaw kontakt',
  howSaioWorksButton: 'Jak działa SAIO?',

  about: {
    button: 'Sprawdź',
  },

  time: {
    header1: 'Oszczędzaj',
    header2: 'czas',
    appendix: 'Zrobotyzowane procesy dzieją się szybciej',
    description: 'Co jeszcze może dać Ci SAIO?',
    button: 'Sprawdź',
  },
  cost: {
    header1: 'Obniżaj',
    header2: 'koszty',
    appendix: 'Zmniejszając ilość pracy ręcznej',
    description: 'Co jeszcze może dać Ci SAIO?',
    button: 'Sprawdź',
  },
  reliability: {
    header1: 'Poczuj',
    header2: 'niezawodność',
    appendix: 'Dzięki robotom, które nie popełniają ludzkich błędów',
    description: 'Co jeszcze może dać Ci SAIO?',
    button: 'Sprawdź',
  },

  whoTrustUs: {
    Strabag: '"Mamy czas na zajęcie się zadaniami trudniejszymi, które bardziej angażują."',
  },
  whoTrustUsHeader: 'Zaufali nam',
};

export default messages;
