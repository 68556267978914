import storeBuilder from '@/store/storeBuilder';
import { FooterState } from './states';
import { FooterSettings } from './types';

const b = storeBuilder.module<FooterState>('footer', new FooterState());

// getters
const footerSettings = b.read((state) => state.footerSettings, 'footerSettings');

export const getters = {
  get getFooterSettings() {
    return footerSettings();
  },
};

// mutations
function setFooterSettings(state: FooterState, settings: FooterSettings) {
  state.footerSettings = {
    isGoRobotGoFooter: settings.isGoRobotGoFooter,
    isVisible: settings.isVisible,
    showTopBorder: settings.showTopBorder,
  };
}

export const mutations = {
  footerSettings: b.commit(setFooterSettings),
};
