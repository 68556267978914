import axios from 'axios';
import { BareActionContext } from 'vuex-typex';
import storeBuilder from '@/store/storeBuilder';
import { LanguageType } from '@/store/modules/enums';
import { API_URL, POLICY_API_BASE_DIR } from '@/config';

import { RootState } from '../rootState';
import { PolicyState } from './states';

type ActionContext = BareActionContext<PolicyState, RootState>;

const b = storeBuilder.module<PolicyState>('policy', new PolicyState());

async function showPrivacyPolicyFromServerDataAsync(context: ActionContext, language: LanguageType) {
  try {
    const response = await axios.get(`${API_URL}policy/download-privacy-policy?Language=${language}`, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data.data], { type: 'application/pdf' });
    const fileURL = POLICY_API_BASE_DIR + URL.createObjectURL(blob);
    window.open(fileURL);
  } catch (error) {
    console.log(error);
  }
}

async function showCookiePolicyFromServerDataAsync(context: ActionContext, language: LanguageType) {
  try {
    const response = await axios.get(`${API_URL}policy/download-cookie-policy?Language=${language}`, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data.data], { type: 'application/pdf' });
    const fileURL = POLICY_API_BASE_DIR + URL.createObjectURL(blob);
    window.open(fileURL);
  } catch (error) {
    console.log(error);
  }
}

async function showPrivacyPolicyFromServerResourceUriAsync(context: ActionContext, language: LanguageType) {
  try {
    const response = await axios.get(`${API_URL}policy/uri-privacy-policy?Language=${language}`);
    window.open(POLICY_API_BASE_DIR + response.data.data.uri);
  } catch (error) {
    console.log(error);
  }
}

async function showCookiePolicyFromServerResourceUriAsync(context: ActionContext, language: LanguageType) {
  try {
    const response = await axios.get(`${API_URL}policy/uri-cookie-policy?Language=${language}`);
    window.open(POLICY_API_BASE_DIR + response.data.data.uri);
  } catch (error) {
    console.log(error);
  }
}

async function showGdprClientsFromServerResourceUriAsync(context: ActionContext, language: LanguageType) {
  try {
    const response = await axios.get(`${API_URL}policy/uri-gdpr-clients?Language=${language}`);
    window.open(POLICY_API_BASE_DIR + response.data.data.uri);
  } catch (error) {
    console.log(error);
  }
}

async function showGdprEmployeesFromServerResourceUriAsync(context: ActionContext, language: LanguageType) {
  try {
    const response = await axios.get(`${API_URL}policy/uri-gdpr-employees?Language=${language}`);
    window.open(POLICY_API_BASE_DIR + response.data.data.uri);
  } catch (error) {
    console.log(error);
  }
}
/* eslint-disable  import/prefer-default-export */
export const actions = {
  showPrivacyPolicyFromServerDataAsync: b.dispatch(showPrivacyPolicyFromServerDataAsync),
  showCookiePolicyFromServerDataAsync: b.dispatch(showCookiePolicyFromServerDataAsync),

  showPrivacyPolicyFromServerResourceUriAsync: b.dispatch(showPrivacyPolicyFromServerResourceUriAsync),
  showCookiePolicyFromServerResourceUriAsync: b.dispatch(showCookiePolicyFromServerResourceUriAsync),
  showGdprClientsFromServerResourceUriAsync: b.dispatch(showGdprClientsFromServerResourceUriAsync),
  showGdprEmployeesFromServerResourceUriAsync: b.dispatch(showGdprEmployeesFromServerResourceUriAsync),
};
