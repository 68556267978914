import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteRecord } from 'vue-router';
import * as headerStore from '@/store/modules/header';
import * as footerStore from '@/store/modules/footer';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  $('body,html').stop(true).animate(
    {
      scrollTop: 0,
    },
    Number(700),
    'easeOutQuad',
  );
  setTimeout(() => {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    (window as any).SEMICOLON.documentOnReady.init();
  }, 1);
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  let isLocalMainLogoVisible = true;
  let useLocalTransparentMenuBackground = false;
  let isLocalGoRobotGoFooter = false;
  let isLocalFooterHidden = false;
  let localShowFooterTopBorder = false;

  if (to.matched.some((route: RouteRecord) => route.meta.isHiddenHeaderLogo)) {
    isLocalMainLogoVisible = false;
  }

  if (to.matched.some((route: RouteRecord) => route.meta.useTransparentMenuBackground)) {
    useLocalTransparentMenuBackground = true;
  }

  if (to.matched.some((route: RouteRecord) => route.meta.isGoRobotGoFooter)) {
    isLocalGoRobotGoFooter = true;
  }

  if (to.matched.some((route: RouteRecord) => route.meta.showFooterTopBorder)) {
    localShowFooterTopBorder = true;
  }

  if (to.matched.some((route: RouteRecord) => route.meta.isFooterHidden)) {
    isLocalFooterHidden = true;
  }

  if (to.matched.some((route: RouteRecord) => route.meta.treatAsPageNotFound)) {
    next({ name: 'notfound' });
    return;
  }

  headerStore.mutations.headerSettings({
    isMainLogoVisible: isLocalMainLogoVisible,
    useTransparentMenuBackground: useLocalTransparentMenuBackground,
  });

  footerStore.mutations.footerSettings({
    isGoRobotGoFooter: isLocalGoRobotGoFooter,
    isVisible: !isLocalFooterHidden,
    showTopBorder: localShowFooterTopBorder,
  });

  next();
});

export default router;
