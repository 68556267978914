import axios from 'axios';
import { API_URL } from '@/config';
import { LanguageType } from '@/store/modules/enums';

interface NewsHeaderResponseModel {
  id: number;
  title: string;
  dateHeader: string;
  imageUrl: string;
}

export interface NewsHeaderModel {
  Id: number;
  Title: string;
  DateHeader: string;
  ImageUrl: string;
}

export interface NewsHeaderRequestInfo {
  newsHeaderModelArray: Array<NewsHeaderModel>;
  dataSuccessfullyRequested: boolean;
}

interface NewsResponseModel {
  id: number;
  title: string;
  dateHeader: string;
  imageHeaderUrl: string;
  content: string;
  authors: Array<string>;
}

export interface NewsModel {
  Id: number;
  Title: string;
  DateHeader: string;
  ImageHeaderUrl: string;
  Content: string;
  Authors: Array<string>;
}

function mapNewsHeaders(headers: Array<NewsHeaderResponseModel>): Array<NewsHeaderModel> {
  return headers.map((value) => {
    const item: NewsHeaderModel = {
      Id: value.id,
      Title: value.title,
      DateHeader: value.dateHeader,
      ImageUrl: value.imageUrl,
    };

    return item;
  });
}

function mapNews(news: NewsResponseModel): NewsModel {
  const item: NewsModel = {
    Id: news.id,
    Title: news.title,
    DateHeader: news.dateHeader,
    ImageHeaderUrl: news.imageHeaderUrl,
    Authors: news.authors,
    Content: news.content,
  };
  return item;
}

export async function getNewsHeaders(
  language: LanguageType,
  pageSize = 0,
): Promise<NewsHeaderRequestInfo> {
  try {
    const response = await axios.get(
      `${API_URL}newsroom/headers?Language=${language}&PageSize=${pageSize}`,
    );
    const { data } = response;
    if (!data.success) {
      console.log('getNewsHeaders respond with status failed.');
    }

    return { dataSuccessfullyRequested: true, newsHeaderModelArray: mapNewsHeaders(data.data) };
  } catch (error) {
    console.log(error);
    return {dataSuccessfullyRequested: true, newsHeaderModelArray:[]};
  }
}

export async function getNews(id: number, language: LanguageType): Promise<NewsModel | null> {
  try {
    const response = await axios.get(`${API_URL}newsroom/${id}?Language=${language}`);
    const { data } = response;
    if (!data.success) {
      console.log('getNews respond with status failed.');
    }

    return mapNews(data.data);
  } catch (error) {
    console.log(error);
    return null;
  }
}
