<template>
  <CookieLaw
    class="cookie-law-container"
    :storageName="storageName"
    :cookieOptions="cookieOptions"
    v-on:accept="onAccepted"
  >
    <div id="cookie-consent-container" class="mx-2 row" slot-scope="props">
      <div id="cookie-consent-message-container" class="col-lg-11 col-10">
        <span>{{ $t("cookieConsent.mainFirstPart") }}</span>
        <router-link :to="{ name: 'legal' }">{{$t("cookieConsent.policiesLing")}}</router-link>
        <span>{{ $t("cookieConsent.mainSecondPart") }}</span>
      </div>
      <div class="col-lg-1 col-2">
        <button class="accept-button-style ml-2" @click="props.accept">
          <span>{{ $t("cookieConsent.acceptBtn") }}</span>
        </button>
      </div>
    </div>
  </CookieLaw>
</template>

<script lang="js">
import CookieLaw from 'vue-cookie-law';
import { Component, Vue } from 'vue-property-decorator';
import * as policyStore from '@/store/modules/policy';
import * as appStore from '@/store/modules/app';

  @Component({
    components: { CookieLaw },
  })

export default class CookieConsent extends Vue {
    policyApi = policyStore;

    appState = appStore;

    storageName = 'cookie:saio-client-portal';

    cookieOptions = {};

    async showPolicyFromServerDataAsync() {
      const { currentLanguage } = this.appState.getters.getAppState;
      await this.policyApi.actions.showPrivacyPolicyFromServerDataAsync(currentLanguage);
      return false;
    }

    async showPolicyFromServerResourceUriAsync() {
      const { currentLanguage } = this.appState.getters.getAppState;
      await this.policyApi.actions.showPrivacyPolicyFromServerResourceUriAsync(currentLanguage);
      return false;
    }

    onAccepted() {
      // in the future for keeping language choice etc.
    }
}
</script>

<style scoped lang="css">
#cookie-consent-container {
  text-align: left;
  font: normal normal normal 14px/21px Verdana;
  letter-spacing: 0;
  color: var(--color-black);
  width: 100%;
}
#cookie-consent-message-container {
}
.cookie-law-container {
  background-color: var(--color-gray);
  opacity: 0.85;
}
.cookie-law-container a {
  font: normal normal bold 14px/21px Verdana;
  color: var(--color-blue);
}
.accept-button-style {
  margin-left: 5px;
  border: 1px solid var(--color-white);
  border-radius: 2px;
  opacity: 1;
  background-color: var(--color-blue);
}
.accept-button-style > span {
  text-align: center;
  font: normal normal normal 14px/19px Verdana;
  letter-spacing: 0;
  color: var(--color-white);
  opacity: 1;
}
.accept-button-style:hover {
  background-color: var(--color-blue);
  border-color: var(--color-blue);
}
</style>
