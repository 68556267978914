
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class TimeTabs extends Vue {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  mounted() {
    ($('.owl-carousel') as any).owlCarousel({
      loop: true,
      autoplay: true,
      autoplaySpeed: 1500,
      autoplayTimeout: 3500,
      margin: 10,
      nav: true,
      items: 1,
      mouseDrag: true,
      touchDrag: false,
    });
  }
}
