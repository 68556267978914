const messages = {
  header: 'SAIO – Success stories',
  headerContent1:
    'At SAIO, over the years, we have gained experience and expertise in AI-driven robotics to provide our customers with comprehensive solutions tailored to their needs.',
  headerContent2:
    'We regularly work with our customers to ensure that SAIO can continue to improve business processes in an ever-changing digital world.',
  implementationsEmpty: 'No implementations',
  readMore: 'Read more',
  clientInfoHeader: 'Client',
  profileInfoHeader: 'Profile',
  dateInfoHeader: 'Implementation',
  challengeHeader: 'Challenge',
  solutionHeader: 'Solution',
  processHeader: 'Process',
  benefitHeader: 'Benefits',
  clientReviewHeader: 'Client’s review',
  otherImplementations: 'Other implementations:',
};

export default messages;
