const messages = {
  legalheader: 'Legal Information',
  privacyheader: 'Privacy Policy',
  legalDocuments: 'Legal documents',
  cookieheader: 'Cookie Policy',
  privacydownloadinfo: 'Privacy Policy can also be downloaded as PDF file ',
  privacydownload: 'here',
  cookiesdownloadinfo: 'Cookie Policy can also be downloaded as PDF file ',
  cookiesdownload: 'here',
  gdprclientsheader: 'GDPR for clients',
  gdpremployeesheader: 'GDPR for employees',

  copyrights: {
    header: 'SAIO is a registered trade mark of ING Bank Śląski S.A. protected by copyrights.',
    lines: [
      'ING Bank Śląski S.A.',
      'ul. Sokolska 34, 40-086 Katowice',
      'KRS: 0000005459',
      'REGON: 271514909',
      'NIP: 6340135475',
    ],
  },

  delivered: {
    header: 'SAIO software is provided by:',
    lines: [
      'SAIO S.A.',
      'ul. Chorzowska 50, 40-101 Katowice',
      'KRS: 0000995194',
      'REGON: 523302035',
      'NIP: 6343015228',
    ],
  },
  
  gdpr: {
    header: 'Contact details of the data protection officer:',
    lines: [
      'Liliana Rother-Obrączka',
      'gdpr@saio.com',
      'SAIO S.A., ul. Chorzowska 50, 40-101 Katowice',
      'Data Protection Officer',
    ],
  },

  privacycontent: [
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'General information',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 1,
      content:
        'This Declaration of privacy protection ("Declaration") includes information about processing of personal data by the SAIO S.A. company, with its register seat in Katowice at ul. Chorzowska 50, 40-101, entered into the Companies Register at the District Court for Katowice - Wschód 8th Commercial Division of the National Court Register under no. KRS 0000408358, REGON (statistical number): 242834901, NIP (taxpayer identification number): 6342805313 ("Company").',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 2,
      content:
        'The Company makes every effort to ensure confidentiality, security and protection of your personal data while its processing, according to applicable law on protection of personal data, including the General Data Protection Regulation ("GDPR") (EU) 2016/679 27th April 2016.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 3,
      content:
        'You can contact the Company via e-mail address: support@saio.com or in writing to the legal seat of the Company.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 4,
      content:
        'In any case concerning the processing of personal data, particularly concerning your rights in connection with the protection of personal data, you can contact with the Company\'s Inspector for protection of data by e-mail: gdpr@saio.com or in writing to the legal seat of the Company (best with the postscript "GDPR").',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 5,
      content:
        "The Company can process your personal data, as specified in the Declaration, as well as during collecting of data from you. Therefore, this Declaration should be written along with the notifications or conditions provided while collecting data from you (or later) or available on other Company's websites, including the Terms of Use of the SAIO.",
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 6,
      content:
        "While using the Company's websites, your personal data can be processed with cookie files, according to the Cookies Policy, available at https://aleo.com/pl_en/about-the-platform/privacy-policy#polityka-cookies.",
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Categories of persons, whose personal data is processed by the Company',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: true,
      linenumber: 7,
      content: 'The Company processes the personal data of natural persons, who:',
      tablecontent: [],
      subelementscontent: [
        'are users of the SAIO, including Non-Registered Users ("Users");',
        'are entrepreneurs, whose data is from public sources, including public register (including KRS and CEIDG) ("Entrepreneurs");',
        'are potential customers, contractors or suppliers of the Company, because, for example provided contact data regarding services and products offered by the Company ("Potential Customers");',
        'act as persons authorized to represent legal persons or other organization units ("Representatives");',
        'are part of applications, complaints or other papers addressed to the Company ("Complainant");',
        'can be addressees of marketing messages according to legal provisions.',
      ],
      subalphalist: true,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Aims and basis of processing of personal data',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 8,
      content:
        'Personal data of Users can be processed by the Company - as the administrator of data - for the following purposes:',
      tablecontent: [
        { header: true, content: ['Purpose of processing', 'Legal basis of processing'] },
        {
          header: false,
          content: [
            "Making and execution of agreement for using the SAIO, including creation and maintenance of the account within the SAIO, confirmation of User's identity, charging a fee, consideration of a complaint, and reporting of abuse.",
            'Necessity of processing to market and execute an agreement; realization of legal obligations on the Company, including the obligations resulting from the act on electronic services.',
          ],
        },
        {
          header: false,
          content: [
            'Maintenance and communication via websites, including chat, electronic contact forms, etc.',
            'Realization of legitimate interests of the Company (e.g. communication with the Users) and additionally agreement - in case it is required (e. g. provisions of telecommunication law).',
          ],
        },
        {
          header: false,
          content: [
            'Direct marketing of products and services (receiving commercial information from various communication channels).',
            'Realization of legitimate interests of the Company and other ING Group Companies (presentation of the offer of their products and services) and additionally agreement - regarding electronic and phone communication channel.',
          ],
        },
        {
          header: false,
          content: [
            'Seeking redress and defence from claims regarding using of the SAIO.',
            'Realization of legitimate interests of the Company (seeking redress and defence from claims).',
          ],
        },
        {
          header: false,
          content: [
            "Carrying out statistical research, analytical purposes, research of User's satisfaction.",
            'Realization of legitimate interests of the Company concerning improvement of offered products and services.',
          ],
        },
        {
          header: false,
          content: [
            'Net and information security provision; restoration of preparedness to work (e. g. backup creation).',
            'Realization of legitimate interests of the Company (provision of security).',
          ],
        },
        {
          header: false,
          content: [
            'Realization of legal obligations resulting from provisions of Polish and EU law.',
            'Realization of legal obligations resulting from provisions of Polish and EU law.',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 9,
      content:
        'Personal data of Entrepreneurs can be processed by the Company - as the administrator of data - for the following purposes:',
      tablecontent: [
        { header: true, content: ['Purpose of processing', 'Legal basis of processing'] },
        {
          header: false,
          content: [
            'Information purposes, regarding publication of information concerning business activity.',
            'Realization of legitimate interests of the Company, regarding strive to achieve improvement of security and confidentiality of commercial transaction, verification of credibility of business subjects, publication of information regarding business activity, including through opinions added on the SAIO.',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 10,
      content:
        'Personal data of Potential Customers can be processed by the Company - as the administrator of data - for the following purposes:',
      tablecontent: [
        { header: true, content: ['Purpose of processing', 'Legal basis of processing'] },
        {
          header: false,
          content: [
            'Making an agreement on cooperation with the Company.',
            'Necessity of processing to make an agreement; realization of legal obligations on the Company, including those resulting from anti - money laundering and counter-terrorism financing act.',
          ],
        },
        {
          header: false,
          content: [
            'Carrying out statistical researches, analytical purposes.',
            'Realization of legitimate interests of the Company regarding improvement of offered products and services.',
          ],
        },
        {
          header: false,
          content: [
            'Seeking redress and defence from claims.',
            'Realization of legitimate interests of the Company (seeking redress and defence from claims).',
          ],
        },
        {
          header: false,
          content: [
            'Realization of legal obligations resulting from provisions of Polish and EU law.',
            'Realization of legal obligations resulting from, among others, tax law provisions and accounting provisions.',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 11,
      content:
        'Personal data of Customers can be processed by the Company - as administrator of data - for the following purposes:',
      tablecontent: [
        { header: true, content: ['Purpose of processing', 'Legal basis of processing'] },
        {
          header: false,
          content: [
            'Execution of agreement with the Company, including realization of payment or other financial transactions, registration and financial settlement of services, products and materials provided for and from the Company.',
            'Necessity of processing to execute the agreement; realization of legal obligations on the Company, including those resulting from anti - money laundering and counter - terrorism financing act.',
          ],
        },
        {
          header: false,
          content: [
            'Direct marketing of products and services (receiving commercial information from various communication channels).',
            'Realization of legitimate interests of the Company and other ING Group Companies (presentation of the offer of their products and services) and additionally agreement - regarding electronic and phone communication channel.',
          ],
        },
        {
          header: false,
          content: [
            'Seeking redress and defence from claims.',
            'Realization of legitimate interests of the Company (seeking redress and defence from claims).',
          ],
        },
        {
          header: false,
          content: [
            'Carrying out statistical researches, analytical purposes.',
            'Realization of legitimate interests of the Company regarding improvement of offered products and services.',
          ],
        },
        {
          header: false,
          content: [
            'Realization of legal obligations resulting from provisions of Polish and EU law.',
            'Realization of legal obligations resulting from, among others, tax law provisions and accounting provisions.',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 12,
      content:
        'Personal data of Representatives and Complainants can be processed by the Company - as the administrator of data - for the following purposes:',
      tablecontent: [
        { header: true, content: ['Purpose of processing', 'Legal basis of processing'] },
        {
          header: false,
          content: [
            'Making and execution of agreement for using the SAIO, including creation and',
            'Necessity of processing to market and execute an agreement; realization of legal',
          ],
        },
        {
          header: false,
          content: [
            "maintenance of the account within the SAIO, confirmation of User's identity, charging a fee, consideration of a complaint, and reporting of abuse.",
            'obligations on the Company, including the obligations resulting from the act on electronic services.',
          ],
        },
        {
          header: false,
          content: [
            'Maintenance and communication via websites, including chat, electronic contact forms, etc.',
            'Realization of legitimate interests of the Company (e.g. communication with the Users) and additionally agreement - in case it is required (e. g. provisions of telecommunication law).',
          ],
        },
        {
          header: false,
          content: [
            'Seeking redress and defence from claims.',
            'Realization of legitimate interests of the Company (seeking redress and defence from claims).',
          ],
        },
        {
          header: false,
          content: [
            'Realization of legal obligations resulting from provisions of Polish and EU law.',
            'Realization of legal provisions resulting from, among others, provision of Civil Code and Code of Civil Procedure.',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 13,
      content:
        'Commercial information is any form of promotion and commercial offer, which regard products and services of the Company and products and services of the ING Group companies. Commercial information can have form of personalized messages based on the profiling operations, which make available proposals of products or services suitable for your preferences, profile of business activity (your and subject represented by you), purchase history, known of predictable expectations.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'How long can the personal data be stored in databases',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: true,
      linenumber: 14,
      content:
        'The period of storage of personal data depend on purposes of its processing and is:',
      tablecontent: [],
      subelementscontent: [
        'regarding execution of agreement for using of the SAIO / agreement on cooperation with the Company and seeking redress and defence from claims resulting from the agreement - during the agreement, and after its termination until the claims resulting from them expire (this period results from the Civil Code);',
        'regarding realization of information purposes, regarding publication of information concerning business activity - until lodging a complaint resulting from your specific situation',
        '(except from lack of legitimate legal basis to processing on the Company side); o regarding maintenance and communication through websites - for the period of 30 days;',
        'regarding handling applications, complaints and other papers addressed to the',
        'Company - until the case regarding the application, complaint or any other paper addressed to the Company expires;',
        "regarding direct marketing - until lodging a complaint or cancelation of the agreement; regarding statistical researches, analytical activities, researches on Customers' satisfaction - during the period of the agreement, and after its termination exclusively in the anonymized or aggregated form (statistical data);",
        'regarding realization of legal obligations - until the document expires (e. g. accounting documents, including data included, require storage for the period of 5 years).',
      ],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'To whom the personal data can be transferred?',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: true,
      linenumber: 15,
      content:
        'Your personal data can be transferred to the following subjects: o other ING Group companies, listed on the following page: https://aleo.com/pl_en/about-the-platform/ing-group - for internal administrative purposes, e. g. regarding reporting within the ING Group, for statistical, accounting and management purposes, including internal management analysis (e. g. regarding realization of requirements resulting from anti-money laundering and counter - terrorism counter);',
      tablecontent: [],
      subelementscontent: [
        'other ING Group companies - for their own marketing purposes (in case of giving a specified agreement);',
        'subjects acting on the basis of law provisions (public authorities), including Prosecutors Office, Police and Tax Office - regarding realization of law obligations on the Company (e. g. regarding possible abuses on the SAIO); o subjects providing marketing and advertising services for the Company or other ING Group companies (e. g. marketing agencies, interactive agencies) - within the scope necessary for realization of these services;',
        'subjects providing technical services for the Company or other ING Group companies (e. g. hosting companies) - within the scope necessary for realization of these services;',
        'subjects providing other types of services for the Company or other ING Group companies, e. g. providers of legal and advisory services - regarding provision of these services.',
      ],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Transfer of personal data outside of the European Economic Area',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 16,
      content:
        'Your personal data can be transferred to subjects of ING Group, of which the Company is part, as well as subjects providing specified services for the Company or other ING Group companies, particularly to United States of America. These companies have not been recognized by the European Commission as countries assuring appropriate level of personal data protection.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 17,
      content:
        'Transfer of personal data to ING Group subjects concern Binding Corporate Rules, endorsed by appropriate Dutch organ controlling obeyance of provisions on personal data protection.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 18,
      content:
        'You can receive a copy of Binding Corporate Rules - in order to do so, please contact the Inspector for the protection of data.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'How do we protect your personal data?',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 19,
      content:
        'We are aware that your personal data is confidential. The Company bears the confidentiality, security and protects your personal data according to applicable law, including GDPR provisions, as well as internal policies and instructions applied by the Company.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 20,
      content:
        'The Company has deployed technological and operational security measures in order to protect your personal data from loss, abuse or unauthorized modification or destruction. This forms of protection include, among others, usage, encryption, appropriate management processes concerning access levels or other technical and organizational means reassuring appropriate protections of your personal data. This measures reassure appropriate level of security regarding threats of character of personal data being subject of protection.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 21,
      content:
        'However, we aware that, in case of transfer of information through Internet, there is no guarantee that it is 100% safe.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 22,
      content: 'We use a safe system of online payments for every payment we charge you online.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Is provision of personal data obligatory?',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 23,
      content:
        'Using of the SAIO, as well as cooperation and contact with the Company is voluntary. However, providing personal data regarding registration on the SAIO, as well as making an agreement on cooperation is necessary to make an agreement and its execution without providing personal data, it is impossible.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 24,
      content:
        'Providing personal data for direct marketing purposes is voluntary - particularly does not determine making and execution of the agreement on the usage of the SAIO.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Rights regarding processing of personal data',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: true,
      linenumber: 25,
      content:
        'Regarding processing of personal data you have rights - based on the provisions on personal data protection, including GDPR provisions - including:',
      tablecontent: [],
      subelementscontent: [
        'right to access to personal data concerning you – you have right to receive a confirmation from the Company regarding the information if your personal data is processed by the Company, as well as concerning other specified information (e. g. concerning purposes of processing, as well as categories of processed personal data). You have also right to access your personal data, in the form of copy of personal data concerning you. This is in order to reassure that you are fully aware of and can check how the Company uses your personal data. The Company can refuse providing you a copy o personal data in case of possibility of negative impact on the rights of other person;',
        'right to correct the data – you have rights to request immediate correction of your personal data, in any case where they are incorrect or incomplete (e. g. the Company processes your incorrect first name, last name or address); in this case you can place an appropriate declaration;',
        'right to delete the data (also known as "right to be forgotten") – makes available request of deletion of your personal data when e. g. data has been used illegally or your agreement has been cancelled (when it has been the only basis of processing of your data). „Right to be forgotten” is not an absolute right to delete your personal data, because there are some exceptions, e. g. when the Company still needs this data to determine, claim or defend your redress or in order to fullfill the legal obligation;',
        'right to limitation of processing the data – you have right to stop the Company from further usage of your personal data when, e. g. the Company requests its correction. In case of limitation of personal data processing, the Company can store your personal data, but it cannot actively use the data anymore (e. g. in order to perform the agreement);',
        'right to transfer the data – you have right to receive and reuse the specified personal data for your own purposes for other subjects (who are independent data administrators). This right can be applied exclusively regarding your personal data, which you have provided for us and which we process (in automated manner) with your agreement or in order to execute the agreement. In this case we will provide you a copy of your personal data in structured, commonly used and in readable machine format or (if it is technically possible), we can send your personal data directly to other administrator of data. The Company can refuse to realize these rights when it could possibly negatively influence rights of other person.',
      ],
      subalphalist: true,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 26,
      content:
        'Within the scope, where the basis of processing of your personal data is realization of legitimate interests you have right to lodge a complaint against processing of this data, particularly regarding its processing for direct marketing purposes. However, the company can continue the processing of personal data in case of possibility of demonstration of important and legally applicable basis of processing which have superior character against your interests, rights or liberty or if it is necessary to seeking or defence of redress. If you refuse to have your data processed for direct marketing, we will not further process your personal data for such purposes.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 27,
      content:
        'Within the scope where the basis of processing of your personal data is your agreement you have right to cancel it at any moment. Cancelation of the agreement does not influence the conformity of processing, made on the basis of agreement before its cancelation.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 28,
      content:
        'You have right to lodge a complaint to an organ controlling the obeyance of the lat (Head of Personal Data Protection Office).',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
  ],

  cookiecontent: [
    {
      subelements: false,
      content:
        'The SAIO does not collect in an automatic method information, apart from information included in the cookie files.',
      subelementscontent: [],
    },
    {
      subelements: false,
      content:
        'Cookie files (so called "cookies") are IT data, particularly text files, stored on final device of the Ale Platform User ("User") and designed for usage of website of the SAIO. Cookies usually contain name of the website they come from, time of storage on the final device and unique number.',
      subelementscontent: [],
    },
    {
      subelements: false,
      content:
        "Subject placing cookie files on the User's final device and accessing them is the Company, as the Operator of the SAIO.",
      subelementscontent: [],
    },
    {
      subelements: true,
      content: 'Cookie files are used for:',
      subelementscontent: [
        "adjustment of content of websites of the SAIO to the User's preferences and optimisation usage of the websites; particularly these files make available detection of the User's device and appropriately show the website, adjusted to their individual needs;",
        'creation of statistics, which facilitate understanding how the Users use the websites, enabling improvement of its structure and content;',
        "maintenance of the User's session (after logging in), so that they does not have to enter their login and password on every website within the SAIO.",
      ],
    },
    {
      subelements: false,
      content:
        'Two types of cookies are used within the SAIO: "session cookies" and "persistent cookies". Session cookies are temporary files stored on the final device of the User until they are logged out, leave the website or close the browser. Persistent cookies are stored on the User\'s final device for a period of time specified in the parameters of cookie files or until they are cleared by the User.',
      subelementscontent: [],
    },
    {
      subelements: true,
      content: 'The following types of cookie files are used within the SAIO:',
      subelementscontent: [
        '„necessary” cookie files, enabling usage of services available within the SAIO, e. g. authentication cookie files used in services requiring authentication within the SAIO;',
        'cookie files used to ensure security, e. g. used to detect abuse within authentication within the SAIO;',
        '„efficiency” / „service” cookie files, enabling gathering of information about way of usage of websites of the SAIO, including usage of Google Analytics tool provided by Google Company (subject to whom the Company entrusted processing of personal data for these purposes);',
        "„functional” cookie files, enabling „memorization” of setting selected by the User and personalization of the User's interface, e. g. within the chosen language or region of the User, font size, website layout etc.;",
        '„marketing” cookie files, enabling providing marketing content more adjusted to the Users hobbies.',
      ],
    },
    {
      subelements: false,
      content:
        "In many cases software used to browse websites (browser) enables storage of cookie files on the final device of the User by default. Users of the SAIO can change the settings concerning cookie files at every moment. Particularly, these setting can be changed in a way to block automatic service of cookie files in the browser setting or inform the User every time they are place on the User's device. Detailed information about possibility and ways of service of cookie files are available in the software settings (browser).",
      subelementscontent: [],
    },
    {
      subelements: false,
      content:
        'The Company informs that the restrictions of usage of cookie files can influence some of the functions available on the websites within the SAIO.',
      subelementscontent: [],
    },
  ],
};

export default messages;
