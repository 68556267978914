import { Vue, Component } from 'vue-property-decorator';
import { getNewsHeaders, NewsHeaderModel, NewsHeaderRequestInfo } from '@/providers/newsroom-api-provider';
import * as appStore from '@/store/modules/app';

@Component
export default class NewsroomMixin extends Vue {
  public posts: Array<NewsHeaderModel> = [];
  public dataSuccessfullyRequested: boolean = false;
  public newsPostPageName = 'news-post';
  public appState = appStore;

  async getNewsHeadersAsync(pageSize = 0) {
    const { currentLanguage } = this.appState.getters.getAppState;
    const newsHeaderRequestInfo: NewsHeaderRequestInfo = await getNewsHeaders(currentLanguage, pageSize);
    this.dataSuccessfullyRequested = newsHeaderRequestInfo.dataSuccessfullyRequested;
    this.posts = newsHeaderRequestInfo.newsHeaderModelArray;
  }

  public get firstPost(): NewsHeaderModel | null {
    if (this.posts.length === 0) {
      return null;
    }

    return this.posts[0];
  }

  public get isVisibleFirstPost(): boolean {
    return this.firstPost !== null;
  }

  public get isVisibleNoPost(): boolean {
    return !this.isVisibleFirstPost && this.dataSuccessfullyRequested;
  }

  public get allPostsWithoutFirst(): Array<NewsHeaderModel> {
    if (this.posts.length === 0) {
      return [];
    }

    return this.posts.slice(1);
  }

  public get allPosts() {
    return this.posts;
  }
}
