
import { Component, Vue } from 'vue-property-decorator';
import { ReactiveFormConfig, ClientLibrary } from '@rxweb/reactive-forms';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import ValidationTranslation from './models/validationTranslation.model';

@Component({
  components: { CookieConsent, Footer, Header },
})
export default class Saio extends Vue {
  constructor() {
    super();
    this.formLoad();
  }

  created() {
    document.title = this.$t('home.mainSite') as string;
  }

  formLoad() {
    const validationTranslations = new ValidationTranslation();

    validationTranslations.required = this.$t('genericForm.required') as string;
    validationTranslations.email = this.$t('genericForm.email') as string;
    validationTranslations.numeric = this.$t('genericForm.numeric') as string;
    validationTranslations.gdpr = this.$t('genericForm.agreement') as string;

    ReactiveFormConfig.clientLib = ClientLibrary.Vue;
    ReactiveFormConfig.set({ validationMessage: validationTranslations });
  }
}
