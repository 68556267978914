const messages = {
  mainSite: 'SAIO - digital processes',

  headerTopSayHiTo: 'SAY HI TO ',
  headerTopS: 'S',
  headerTopO: 'O',
  headerTopAI: 'AI',
  headerBottom: '',
  headerDescription:
    'In 10 years, robots will do 90% of time-consuming tasks for us. Move your company to the future - start robotising now.',
  leaveContactButton: 'Contact us',
  howSaioWorksButton: 'How does SAIO work?',

  about: {
    button: 'Learn more',
  },

  time: {
    header1: 'Save',
    header2: 'time',
    appendix: 'Robotic processes happen faster',
    description: 'What else will SAIO give you?',
    button: 'Learn more',
  },
  cost: {
    header1: 'Cut',
    header2: 'costs',
    appendix: 'Reduce manual work',
    description: 'What else will SAIO give you?',
    button: 'Learn more',
  },
  reliability: {
    header1: 'Feel',
    header2: 'reliability',
    appendix: 'Robots don’t make human mistakes',
    description: 'What else will SAIO give you?',
    button: 'Learn more',
  },

  whoTrustUs: {
    Strabag: '"Mamy czas na zajęcie się zadaniami trudniejszymi, które bardziej angażują."',
  },
  whoTrustUsHeader: 'They trusted us',
};

export default messages;
