import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as appStore from '@/store/modules/app';
import { LanguageType } from '@/store/modules/enums';
import configEn from './en/index';
import configPl from './pl/index';

Vue.use(VueI18n);

const messages = {
  en: configEn,
  pl: configPl,
};

const language = window.navigator.language.split('-')[0];
const availableLanguages = Object.keys(messages);

const i18n = new VueI18n({
  locale: availableLanguages.indexOf(language) >= 0 ? language : availableLanguages[0],
  messages,
});

const currentLanguage: LanguageType = language === 'pl' ? LanguageType.Pl : LanguageType.Default;
appStore.mutations.appState({
  currentLanguage,
});

export default i18n;
