const messages = {
  title: 'Become a partner',
  header:
    'Add SAIO to your offer and bring your customers to the next level of technology. Choosing us for your clients will provide you:',

  consulting: [
    {
      header: 'Innovative product',
      description1: '…that can become a showcase of your modern company.',
      description2: '',
    },
    {
      header: 'Relationship support',
      description1:
        'By offering SAIO, you are giving your customers an effective and proven solution.',
      description2: '',
    },
    {
      header: 'Inspiring team',
      description1:
        'By adding SAIO to your offer, you will receive the support and inspiration from our experts.',
      description2: '',
    },
  ],

  leaveContactButton: 'Contact us',
};

export default messages;
