const messages = {
  header: 'Chcesz wiedzieć więcej?',
  contact: 'Zostaw kontakt',
  whySaio: 'Dlaczego wybrać SAIO?',
  implementations: 'Udane wdrożenia',
  aboutSaio: 'O SAIO',
  technology: 'Technologia SAIO',
  newsroom: 'Newsroom',
};

export default messages;
