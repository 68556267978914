
import { Component, Vue } from 'vue-property-decorator';
import routes from '@/router/routes';
import * as headerStore from '@/store/modules/header';

@Component
export default class Header extends Vue {
  private routes = routes;

  headerStore = headerStore;

  d1 =
    'm 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20';

  d2 =
    'm 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20';

  get isMainLogoVisible() {
    return this.headerStore.getters.getHeaderSettings?.isMainLogoVisible;
  }

  get headerClasses() {
    const styleData = {
      header: !this.headerStore.getters.getHeaderSettings?.useTransparentMenuBackground
        ? ''
        : 'header-main-site',
    };

    return [styleData.header];
  }
}
