import axios from 'axios';
import { API_URL } from '@/config';
import { LanguageType } from '@/store/modules/enums';

interface ClaimResponseModel {
  id: number;
  content: string;
  personName: string;
  personPosition: string;
  company: string;
}

export interface ClaimModel {
  Id: number;
  Content: string;
  PersonName: string;
  PersonPosition: string;
  Company: string;
}

function mapClaim(claim: ClaimResponseModel): ClaimModel {
  const item: ClaimModel = {
    Id: claim.id,
    Content: claim.content,
    PersonName: claim.personName,
    PersonPosition: claim.personPosition,
    Company: claim.company,
  };
  return item;
}

export async function getRandomClaim(language: LanguageType): Promise<ClaimModel | null> {
  try {
    const response = await axios.get(`${API_URL}claims/randomClaim?Language=${language}`);
    const { data } = response;
    if (!data.success) {
      console.log('getRandomClaim respond with status failed.');
    }

    return mapClaim(data.data);
  } catch (error) {
    console.log(error);
    return null;
  }
}
