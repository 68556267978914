
import { Component, Vue } from 'vue-property-decorator';
import * as footerStore from '@/store/modules/footer';
import * as policyStore from '@/store/modules/policy';
import * as appStore from '@/store/modules/app';

@Component
export default class Footer extends Vue {
  footerStore = footerStore;

  policyApi = policyStore;

  appState = appStore;

  get isVisible() {
    return this.footerStore.getters.getFooterSettings?.isVisible;
  }

  get footerAppendixText() {
    return this.footerStore.getters.getFooterSettings?.isGoRobotGoFooter
      ? this.$t('footer.headerGoRobotGoAppendix')
      : this.$t('footer.headerLetsMakeRobotAppendix');
  }

  get footerClasses() {
    const styleData = {
      dark: '',
      footer: this.footerStore.getters.getFooterSettings?.showTopBorder
        ? 'footer-top-border'
        : 'footer-no-border',
    };

    return [styleData.dark, styleData.footer];
  }

  async showPrivacyPolicyFromServerResourceUriAsync() {
    const { currentLanguage } = this.appState.getters.getAppState;
    await this.policyApi.actions.showPrivacyPolicyFromServerResourceUriAsync(currentLanguage);
    return false;
  }

  async showCookiePolicyFromServerResourceUriAsync() {
    const { currentLanguage } = this.appState.getters.getAppState;
    await this.policyApi.actions.showCookiePolicyFromServerResourceUriAsync(currentLanguage);
    return false;
  }
}
