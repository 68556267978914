import { render, staticRenderFns } from "./AboutRobotisation.vue?vue&type=template&id=997daf2a&scoped=true"
import script from "./AboutRobotisation.vue?vue&type=script&lang=ts"
export * from "./AboutRobotisation.vue?vue&type=script&lang=ts"
import style0 from "./AboutRobotisation.vue?vue&type=style&index=0&id=997daf2a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "997daf2a",
  null
  
)

export default component.exports