const messages = {
  required: 'This field is required',
  email: 'Please fill your email correctly',
  numeric: 'Please use only numbers',
  agreement: 'Agreement required',
  thankYou:
    'Thank you! Your message has been sent. You will receive a confirmation to the email adress you provided.',
  back: 'Back',
  sorry: "We are sorry, we couldn't deliver the message, please try again.",
};

export default messages;
