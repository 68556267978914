const messages = {
  header: 'Say Hi to SAIO -  contact us',
  subHeader: 'Prefer to call?',
  phoneNum01: '+48 571-409-880',
  phoneNum02: '+48 571-409-861',
  phoneInfo: 'Mon - Fri, 9-17 CET',

  fullName: 'Full Name',
  company: 'Company',
  phoneNumber: 'Phone number',
  email: 'Email',
  beClient: 'Become a client',
  bePartner: 'Become a partner',
  gdpr: 'On behalf of the Company, I certify that it is interested in the services provided by SAIO S.A. with headquarters in Katowice, ul. Chorzowska 50, 40-101 Katowice (the Company), including receiving commercial information from the Company. In connection with the above, I consent to the processing by the Company of my personal data provided in this form solely for the purpose of presenting products and services offered by the Company. This consent is voluntary and valid until revoked, and if not revoked, up to 24 months from the month in which the consent was given.',
  sendButton: 'Send',

  about: {
    header: "SAIO is a platform that robotises your company's processes",
    appendix: 'Want to find out what Robotic Process Automation is?',
    checkButton: 'Learn more',
    description: 'Feel reliability',
    descriptionAppendix: 'Robots don’t make human mistakes',
    whatElseCanSaioGive: 'What else will SAIO give you?',
  },
};

export default messages;
