const messages = {
  header: 'Newsroom',
  loadMore: 'load more',
  noPosts: 'There is no posts',
  snippetLink: 'Newsroom >',
  youMightAlsoLike: 'You might also like:',
  authors: 'Author: ',
};

export default messages;
