import axios from 'axios';
import { BareActionContext } from 'vuex-typex';
import storeBuilder from '@/store/storeBuilder';
import { API_URL } from '@/config';
import { Mail } from '@/store/modules/types';
import { RootState } from '../rootState';
import { MailState } from './states';

type ActionContext = BareActionContext<MailState, RootState>;

const b = storeBuilder.module<MailState>('mail', new MailState());

async function sendAsync(context: ActionContext, payload: Mail) {
  try {
    const response = await axios.post(`${API_URL}mailing/contact`, payload);
    return response.data.success;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/* eslint-disable  import/prefer-default-export */
export const actions = {
  sendAsync: b.dispatch(sendAsync),
};
