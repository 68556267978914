const messages = {
  header: 'Do you want to know more?',
  contact: 'Contact us',
  whySaio: 'Why choose SAIO?',
  implementations: 'Successful implementations',
  aboutSaio: 'About SAIO',
  technology: 'Technology in SAIO',
  newsroom: 'Newsroom',
};

export default messages;
