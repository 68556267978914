const messages = {
  header: 'SAIO – virtual intelligence, virtual workforce',
  appendixTop:
    'Robotic Process Automation (RPA) is a technology used for automating repetitive, rule-based computer tasks (like moving data between applications) by simply mimicking the way humans work with applications - using mouse and keyboard.',
  appendixBottom:
    'IN SAIO, we combine our experience in robotisation with AI technology to provide end-to-end solutions and become a leader in our industry.',

  about: 'About SAIO',
  aboutDescription1:
    'We started in 2016 as RoboPlatform, automating processes for ING in Poland and for the whole group. Working for the bank required us to be very concerned about safety and efficiency – areas that are still of the utmost importance to us.',
  aboutDescription2:
    'Today, we successfully automate processes in many companies from different industries and countries.',
  worldwide: 'SAIO robots around the world',
  places: 'Amsterdam Madrid Milan Paris Warsaw Geneva Bucharest Sydney',

  implementations: 'Nasze realizacje',
  implementationsDescription: 'Zobacz nasze ostatnie wdrożenia i co dobrego z nich wynikło',
};

export default messages;
