const messages = {
  'why-saio': 'Dlaczego SAIO',
  implementations: 'Nasze wdrożenia',
  become: 'Współpraca',
  beClient: 'Zostań klientem',
  bePartner: 'Zostań partnerem',
  saio: 'O SAIO',
  technology: 'Technologia',
  newsroom: 'Newsroom',
  contact: 'Kontakt',
  language: 'Język',
  privacy: 'Polityka prywatności',
  cookies: 'Polityka cookies',
  legal: 'Informacje prawne',
  'contact-form': 'Kontakt',
  about: 'O nas',
  robots: 'Roboty od SAIO',
};

export default messages;
