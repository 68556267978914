const messages = {
  header: 'Technologia',
  description1:
    'Prawie w każdej firmie istnieje wiele powtarzalnych i monotonnych procesów, które pracownicy obsługują ręcznie.',
  description2: 'Ale nie musi tak być!',
  appendix: 'Chcesz dowiedzieć się, co może dać Ci SAIO?',

  technologyHeader: 'In robots we trust',

  statisticNumber: '90',
  statisticHeader: '90% dużych organizacji do 2022 roku wdroży rozwiązania robotyczne. Dlaczego?',
  statisticDescription:
    'Organizacje te dążą do cyfrowego wzmocnienia kluczowych procesów biznesowych. Robotyzacja może im w tym pomóc, ponieważ zapewnia odporność i skalowalność procesów. Poprawia też wydajność pracy pracowników, którzy zamiast wykonywać powtarzalne i monotonne czynności, mogą skupić się na bardziej kreatywnych zadaniach.',
  statisticAppendix: 'GARTNER 2020',

  options: [
    {
      header: 'Lepsze wyniki biznesowe',
      description:
        'Pracownicy zyskają więcej czasu na kreatywną pracę. Efekt? Większa wydajność i niższe koszty.',
    },
    {
      header: 'Mniejsze ryzyko błędu ludzkiego',
      description:
        'Monotonna praca powoduje stres i frustrację. Emocje te mogą powodować błędy, które nie wystąpią w przypadku robotów.',
    },
    {
      header: 'Ograniczenie kosztów',
      description:
        'Nie musisz zatrudniać i szkolić nowych pracowników, żeby obsłużyć rosnące wolumeny.',
    },
    {
      header: 'Kultura innowacyjności',
      description:
        'Chcesz uchodzić za nowoczesnego pracodawcę? Pozwól pracownikom budować kompetencje cyfrowe i wykorzystać je w działaniu.',
    },
    {
      header: 'Stabilność i bezpieczeństwo',
      description:
        'Zrobotyzowane procesy działają bez zakłóceń. Jest to szczególnie ważne teraz, kiedy większość firm działa zdalnie.',
    },
    {
      header: 'Odporność na skoki wolumenów',
      description:
        'Nieważne, czy np. masz do przygotowania 10 czy 100 raportów – proces zawsze będzie działać sprawnie.',
    },
    {
      header: 'Zadowoleni pracownicy',
      description:
        'Powtarzalne czynności powierz robotom. Pracownicy mogą w tym czasie rozwijać swoje kompetencje.',
    },
  ],
};

export default messages;
