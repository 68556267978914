const messages = {
  headerGoRobotGoAppendix: 'Go, Robot, go! >',
  headerLetsMakeRobotAppendix: "Let's make robots >",

  team: 'POWERED BY ING',
  copyrights: '© 2024 SAIO S.A. All rights reserved.',
  createdBy: 'DESIGNED BY TOFU STUDIO',

  saiocopyright: {
    trademark: 'SAIO is a registered trade mark of ING Bank Śląski S.A. protected by copyrights.',
    delivered: 'SAIO software is provided by SAIO S.A.',
  },
};

export default messages;
