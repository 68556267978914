const messages = {
  headerGoRobotGoAppendix: 'Go, Robot, go! >',
  headerLetsMakeRobotAppendix: "Let's make robots >",

  team: 'POWERED BY ING',
  copyrights: '© 2024 SAIO S.A. All rights reserved.',
  createdBy: 'DESIGNED BY TOFU STUDIO',

  saiocopyright: {
    trademark:
      'SAIO jest zarejestrowanym znakiem towarowym na rzecz ING Banku Śląskiego S.A. Wszystkie prawa zastrzeżone.',
    delivered: 'Oprogramowanie SAIO jest dostarczane przez SAIO S.A.',
  },
};

export default messages;
