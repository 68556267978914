const messages = {
  header: 'SAIO - virtual intelligence, virtual workforce',
  headerDescription:
    'Łączymy nasze doświadczenie w robotyzacji z technologią sztucznej inteligencji, aby dostarczać kompleksowe rozwiązania i stać się liderem branży.',

  about: {
    header: 'O SAIO',
    description:
      'Zaczęliśmy w 2016 roku jako RoboPlatform, robotyzując procesy dla ING w Polsce i na świecie. Praca dla banku wymagała od nas dużej troski o bezpieczeńststwo i efektywność - obszary, które cały czas są dla nas najważniejsze. Dziś z powodzeniem robotyzujemy procesy w wielu firmach z różnych branż i z różnych krajów.',
  },

  location: {
    header: 'Gdzie jesteśmy:',
    description: [
      'Amsterdam',
      'Madryt',
      'Mediolan',
      'Paryż',
      'Warszawa',
      'Genewa',
      'Bulareszt',
      'Sydney',
    ],
  },

  realization: {
    header: 'Nasze realizacje',
    description: 'Zobacz nasze ostatnie wdrożenia i co dobrego z nich wynikło',
  },
};

export default messages;
