const messages = {
  required: 'To pole jest wymagane',
  email: 'Proszę poprawnie wypełnić email',
  numeric: 'Proszę wypełnić jedynie cyframi',
  agreement: 'Wymagana zgoda',
  thankYou:
    'Dziękujemy! Twoja wiadomość została wysłana. Otrzymasz potwierdzenie na podany adres email.',
  back: 'Powrót',
  sorry: 'Przepraszamy, tym razem nie mogliśmy dostarczyć wiadomości, spróbuj ponownie.',
};

export default messages;
