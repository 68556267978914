
import { Component, Vue } from 'vue-property-decorator';
import { getRandomClaim, ClaimModel } from '@/providers/claimsApiProvider';
import * as appStore from '@/store/modules/app';

@Component({})
export default class Claims extends Vue {
  public appState = appStore;

  public claim: ClaimModel | null = null;

  public shouldRenderClaims = false;

  mounted() {
    this.getRandomClaimAsync();
  }

  async getRandomClaimAsync() {
    const { currentLanguage } = this.appState.getters.getAppState;
    this.claim = await getRandomClaim(currentLanguage);

    if (this.claim !== null) {
      this.shouldRenderClaims = true;
    }
  }
}
