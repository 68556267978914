const messages = {
  title: 'Zostań klientem',
  header:
    'Każda firma ma inne potrzeby - dlatego dostosujemy nasz produkt do Twoich wymagań. Pracujemy według sprawdzonego planu:',

  consulting: [
    {
      header: 'Consulting',
      description1:
        'Umów się z nami i opowiedz o swoich procesach. Przeanalizujemy je i zaproponujemy najlepsze rozwiązanie.',
      description2: '',
    },
    {
      header: 'Wdrożenie',
      description1:
        'W terminie, który wybierzesz, nasi specjaliści wdrożą SAIO w Twojej firmie. Możesz też zainstalować SAIO samodzielnie – jeżeli się na to zdecydujesz, możesz liczyć na naszą pomoc.',
      description2: '',
    },
    {
      header: 'Development',
      description1:
        'Nasi specjaliści przygotują roboty do wybranych procesów. Zwykle zajmuje nam to 2 tygodnie – ale czas może się różnić, w zależności od tego, jak bardzo skomplikowany jest proces, który chcesz zrobotyzować.',
      description2:
        'Gdy robot będzie gotowy, poprosimy Cię o przeprowadzenie testu. Jeżeli uznasz, że potrzebne są poprawki, zajmiemy się nimi.',
    },
    {
      header: 'Szkolenie',
      description1:
        'Przekażemy wiedzę Twoim pracownikom i pokażemy, jak tworzyć roboty i jak nimi zarządzać. ',
      description2: '',
    },
  ],

  leaveContactButton: 'Zostaw kontakt',
};

export default messages;
