import storeBuilder from '@/store/storeBuilder';
import { HeaderState } from './states';
import { HeaderSettings } from './types';

const b = storeBuilder.module<HeaderState>('header', new HeaderState());

// getters
const headerSettings = b.read((state) => state.headerSettings, 'headerSettings');

export const getters = {
  get getHeaderSettings() {
    return headerSettings();
  },
};

// mutations
function setHeaderSettings(state: HeaderState, settings: HeaderSettings) {
  state.headerSettings = {
    isMainLogoVisible: settings.isMainLogoVisible,
    useTransparentMenuBackground: settings.useTransparentMenuBackground,
  };
}

export const mutations = {
  headerSettings: b.commit(setHeaderSettings),
};
