const messages = {
  title: 'Become a customer',
  header:
    "Each company has different needs - that's why we will adapt our product to your requirements. We work according to a proven plan:",

  consulting: [
    {
      header: 'Consulting',
      description1:
        'Make an appointment with us and tell us about your processes. We will analyze them and propose you the best solution.',
      description2: '',
    },
    {
      header: 'Implementation',
      description1:
        'On the date you choose, our specialists will implement SAIO in your company. You can also install SAIO yourself - if you choose to do so, we will help you.',
      description2: '',
    },
    {
      header: 'Development',
      description1:
        'Our specialists will prepare robots for your processes. This usually takes us 2 weeks - but the time may vary depending on how complicated the processes are.',
      description2:
        "When the robot is ready, we will ask you to do a test. If you need corrections, we'll take care of them.",
    },
    {
      header: 'Training',
      description1: 'We will train your employees and show how to create and manage robots.',
      description2: '',
    },
  ],

  leaveContactButton: 'Contact us',
};

export default messages;
