const messages = {
  header: 'Dlaczego SAIO?',
  description:
    'Roboty SAIO powstały w ING. Są obecne w wielu procesach bankowych, ponieważ gwarantują stabilność i bezpieczeństwo.',
  appendix: 'Chcesz dowiedzieć się, czym jest robotyzacja?',
  checkButton: 'Sprawdź',

  saioOverall: 'To wszystko zapewni Ci SAIO:',
  saioOverallItems: [
    {
      header: 'Bezpieczeństwo',
      descriptionLine1: 'SAIO spełnia najwyższe',
      descriptionLine2: 'wymogi bezpieczeństwa',
      descriptionLine3: '(SOX, CIA triad).',
    },
    {
      header: 'Szybkie wdrożenie',
      descriptionLine1: 'Na duże efekty ',
      descriptionLine2: 'potrzebujemy niewiele czasu.',
      descriptionLine3: '',
    },
    {
      header: 'Stabilność',
      descriptionLine1: 'Skoki wolumenów',
      descriptionLine2: ' nie będą Ci straszne.',
      descriptionLine3: '',
    },
    {
      header: 'Kontrola',
      descriptionLine1: 'Możesz łatwo',
      descriptionLine2: 'zarządzać robotami',
      descriptionLine3: 'przez aplikację.',
    },
    {
      header: 'Elastyczność',
      descriptionLine1: 'Dopasujemy roboty',
      descriptionLine2: 'do Twoich procesów.',
      descriptionLine3: 'Nigdy na odwrót.',
    },
  ],

  adjustments1: 'Roboty dla każdej branży.',
  adjustments2: 'Od SAIO',

  processesHeader: 'Przykładowe procesy',
  processesItems: [
    {
      header: 'HR',
      items: [
        'Aktualizacja informacji o pracownikach',
        'Tworzenie umów',
        'Automatyczne tworzenie i publikowanie ogłoszeń o pracę',
        'Zautomatyzowana komunikacja z pracownikami',
        'Onboarding pracowników',
      ],
    },
    {
      header: 'Księgowość',
      items: [
        'Przetwarzanie faktur',
        'Tworzenie kartotek dostawców i odbiorców',
        'Tworzenie kont księgi głównej',
        'Aktywacja środków trwałych',
        'OCR faktur',
        'Prognozowanie przepływów pieniężnych',
        'Rekoncyliacja kont',
        // 'Generowanie gwarancji bankowych',
        // 'Integracja między systemami ERP i bankowymi',
        // 'Generowanie raportów',
        // 'Wprowadzanie danych do ERP'
      ],
    },
    {
      header: 'Zakupy',
      items: [
        'Łączenie faktur z zamówieniami (PO)',
        'Rekalkulacja cen fakturowanych',
        'Rozliczanie wydatków służbowych i firmowych kart płatniczych',
      ],
    },
    {
      header: 'KYC',
      items: ['Coroczna weryfikacja klienta', 'Agregacja danych', 'OCR dokumentów tożsamości'],
    },
    {
      header: 'Operacje',
      items: [
        'Elektroniczny obieg dokumentów',
        'Zautomatyzowana obsługa klienta',
        'Kategoryzacja skrzynek pocztowych',
        'Klasyfikacja dokumentów',
      ],
    },
    {
      header: 'IT',
      items: ['Zautomatyzowana obsługa ticketów', 'Zarządzanie dostępem użytkowników'],
    },
  ],
};

export default messages;
