
import { Component, Emit, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Post from '@/components/Post.vue';
import NewsroomMixin from '@/mixins/newsroomMixin';

@Component({
  components: {
    Post,
  },
})
export default class NewsSnippet extends mixins(NewsroomMixin) {
  @Prop({ required: false }) postsPageSize: number | undefined;

  @Prop({ required: false }) useBlackFont: boolean | undefined;

  @Prop({ required: false }) currentNewsId: number | undefined;

  private defaultPostsPageSize = 2;

  public newsId: number = this.currentNewsId ?? 0;

  mounted() {
    this.getData();
  }

  get arePostsAvailable() {
    return this.posts && this.posts.length > 0;
  }

  get actualPostsPageSize() {
    let pageSize = this.postsPageSize ?? this.defaultPostsPageSize;
    if (this.currentNewsId) {
      pageSize += 1;
    }

    return pageSize;
  }

  get useBlackFontFlag() {
    return this.useBlackFont ?? false;
  }

  get linkClasses() {
    return this.useBlackFont ? 'newsroom-link-text-color-black' : 'newsroom-link-text-color-white';
  }

  @Emit('onIdChanged')
  public onIdChanged(newNewsId: number) {
    this.newsId = newNewsId;
    this.getData();
    return newNewsId;
  }

  async getData() {
    await this.getNewsHeadersAsync(this.actualPostsPageSize);
    if (this.newsId) {
      if (this.allPosts.find((f) => f.Id === this.newsId)) {
        this.posts = this.allPosts.filter((f) => f.Id !== this.newsId);
      } else {
        this.posts = this.allPosts.slice(0, this.actualPostsPageSize - 1);
      }
    }
  }
}
