const messages = {
  header: 'Why SAIO?',
  description:
    'SAIO robots were developed at ING. They are used in many banking processes because they guarantee stability and security.',
  appendix: 'Want to find out what Robotic Process Automation is?',
  checkButton: 'Learn more',

  saioOverall: 'What SAIO gives you:',
  saioOverallItems: [
    {
      header: 'Safety',
      descriptionLine1: 'SAIO meets the highest',
      descriptionLine2: 'security requirements',
      descriptionLine3: '(SOX, CIA triad).',
    },
    {
      header: 'Fast implementation',
      descriptionLine1: 'We need little time',
      descriptionLine2: 'for big results.',
      descriptionLine3: '',
    },
    {
      header: 'Stability',
      descriptionLine1: 'Volume peaks will not',
      descriptionLine2: 'scare you.',
      descriptionLine3: '',
    },
    {
      header: 'Control',
      descriptionLine1: 'You can easily manage',
      descriptionLine2: 'your robots',
      descriptionLine3: 'through the app.',
    },
    {
      header: 'Flexibility',
      descriptionLine1: 'We customise robots',
      descriptionLine2: 'to your processes.',
      descriptionLine3: 'Not the other way.',
    },
  ],

  adjustments1: 'Robots for all industries.',
  adjustments2: 'From SAIO',

  processesHeader: 'Example processes',
  processesItems: [
    {
      header: 'HR',
      items: [
        'Update information about employees',
        'Agreements creation',
        'Automated job ads creation and publishing',
        'Automated communication with employees',
        'Employee onboarding',
      ],
    },
    {
      header: 'Accounting',
      items: [
        'Invoice processing',
        'Suppliers files creation',
        'General Ledger Accounts creation',
        'Fixed assets activation',
        'OCR invoices',
        'Cashflow forecasting',
        'Accounting reconciliation',
        // 'Bank guarantees generation',
        // 'Integration between ERP and banking systems',
        // 'Reports generation',
        // 'ERP data entry'
      ],
    },
    {
      header: 'Treasury',
      items: [
        'Matching invoices with purchase orders',
        'Prices recalculation',
        'Business expenditure/cards settlements',
      ],
    },
    {
      header: 'KYC',
      items: ['Annual verification', 'Data aggregation', 'ID’s OCR'],
    },
    {
      header: 'Operations',
      items: [
        'Electronic documents flow (circulation)',
        'Automated client service',
        'Mailbox categorisation',
        'Documents classification',
      ],
    },
    {
      header: 'IT',
      items: ['Automated ticket service', 'Users access management'],
    },
  ],
};

export default messages;
