const messages = {
  header: 'SAIO – virtual intelligence, virtual workforce',
  appendixTop:
    'Robotic Process Automation (RPA) to technologia, która pozwala zautomatyzować powtarzalne zadania (np. przenoszenie danych między aplikacjami), poprzez naśladowanie sposobu, w jaki pracują ludzie – za pomocą myszy lub klawiatury.',
  appendixBottom:
    'Łączymy nasze doświadczenie w robotyzacji z technologią sztucznej inteligencji, aby dostarczać kompleksowe rozwiązania i stać się liderem branży.',

  about: 'O SAIO',
  aboutDescription1:
    'Zaczęliśmy w 2016 roku jako RoboPlatform, robotyzując procesy dla ING w Polsce i na świecie. Praca dla banku wymagała od nas dużej troski o bezpieczeństwo i efektywność – obszary, które cały czas są dla nas najważniejsze.',
  aboutDescription2:
    'Dziś z powodzeniem robotyzujemy procesy w wielu firmach z różnych branż i z różnych krajów.',

  worldwide: 'Roboty SAIO na świecie',
  places: 'Amsterdam Madryt Mediolan Paryż Warszawa Genewa Bukareszt Sydney',

  implementations: 'Nasze realizacje',
  implementationsDescription: 'Zobacz nasze ostatnie wdrożenia i co dobrego z nich wynikło',
};

export default messages;
