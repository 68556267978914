export enum LanguageType {
  Default = 0,
  Pl = 1,
}

export enum MailTargetType {
  Default = 0,
  Partner = 1,
  Client = 2,
  BothClientPartner = 4,
}
