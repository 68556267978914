import { LanguageType } from '@/store/modules/enums';
import {
  App, FooterSettings, HeaderSettings, Mail, Policy, User,
} from './types';

export class AuthState {
  authUser: User | null = null;
}

export class MailState {
  form: Mail | null = null;
}

export class HeaderState {
  headerSettings: HeaderSettings | null = null;
}

export class FooterState {
  footerSettings: FooterSettings | null = null;
}

export class PolicyState {
  policy: Policy | null = null;
}

export class AppState {
  appState: App = {
    currentLanguage: LanguageType.Default,
  };
}
