const messages = {
  'why-saio': 'Why SAIO',
  implementations: 'Our implementations',
  become: 'Cooperation',
  beClient: 'For customers',
  bePartner: 'For partners',
  saio: 'About SAIO',
  technology: 'Technology',
  newsroom: 'Newsroom',
  contact: 'Contact',
  language: 'Language',
  privacy: 'Privacy Policy',
  cookies: 'Cookie Policy',
  legal: 'Legal Information',
  'contact-form': 'Contact',
  about: 'About US',
  robots: 'Robots from SAIO',
};

export default messages;
