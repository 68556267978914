const messages = {
  header: 'Say Hi to SAIO – skontaktuj się z nami',
  subHeader: 'Wolisz zadzwonić?',
  phoneNum01: '+48 571-409-880',
  phoneNum02: '+48 571-409-861',
  phoneInfo: 'pn. - pt., 9-17 CET',

  fullName: 'Imię i Nazwisko',
  company: 'Firma',
  phoneNumber: 'Numer telefonu',
  email: 'Email',
  beClient: 'Chcę zostać klientem',
  bePartner: 'Chcę zostać partnerem',
  gdpr: 'Oświadczam w imieniu Firmy, że jest ona zainteresowana usługami świadczonymi przez SAIO S.A. z siedzibą w Katowicach, ul. Chorzowska 50, 40-101 Katowice (Spółka), w tym otrzymywaniem od Spółki  informacji handlowej. W związku z powyższym wyrażam zgodę na przetwarzanie przez Spółkę moich danych osobowych podanych w niniejszym formularzu wyłącznie w celu prezentacji produktów i usług oferowanych przez Spółkę. Niniejsza zgoda jest dobrowolna i ważna do czasu jej odwołania, a jeśli nie zostanie odwołana, do 24 miesięcy od miesiąca, w którym zgoda została udzielona.',
  sendButton: 'Wyślij',

  about: {
    header: 'SAIO to system, który zrobotyzuje procesy w Twojej firmie',
    appendix: 'Chcesz wiedzieć, czym jest robotyzacja?',
    checkButton: 'Sprawdź',
    description: 'Poczuj niezawodność',
    descriptionAppendix: 'Dzięki robotom, które nie popełniają ludzkich błędów',
    whatElseCanSaioGive: 'Co jeszcze może dać Ci SAIO?',
  },
};

export default messages;
