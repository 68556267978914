const messages = {
  header: 'SAIO - virtual intelligence, virtual workforce',
  headerDescription:
    'We combine our experience in robotisation with AI technology to provide end-to-end solutions and become a leader in our industry.',

  about: {
    header: 'About SAIO',
    description:
      'We started in 2016 as RoboPlatform, automating processes for ING in Poland and for the whole group. Working for the bank required us to be very concerned about safety and efficiency – areas that are still of the utmost importance to us.',
  },

  location: {
    header: 'Where we are:',
    description: [
      'Amsterdam',
      'Madrid',
      'Milan',
      'Paris',
      'Warsaw',
      'Geneva',
      'Bucharest',
      'Sydney',
    ],
  },

  realization: {
    header: 'Nasze realizacje',
    description: 'Zobacz nasze ostatnie wdrożenia i co dobrego z nich wynikło',
  },
};

export default messages;
