const messages = {
  legalheader: 'Informacje prawne',
  privacyheader: 'Polityka Prywatności',
  legalDocuments: 'Dokumenty prawne',
  cookieheader: 'Polityka Cookies',
  privacydownloadinfo: 'Politkę prywatności można także pobrać jako plik PDF ',
  privacydownload: 'tutaj',
  cookiesdownloadinfo: 'Politkę cookies można także pobrać jako plik PDF ',
  cookiesdownload: 'tutaj',
  gdprclientsheader: 'Klauzula informacyjna dla klientów',
  gdpremployeesheader: 'Klauzula informacyjna dla pracowników dostawców',

  copyrights: {
    header:
      'SAIO jest zarejestrowanym znakiem towarowym na rzecz ING Banku Śląskiego S.A. Wszystkie prawa zastrzeżone.',
    lines: [
      'ING Bank Śląski S.A.',
      'ul. Sokolska 34, 40-086 Katowice',
      'KRS: 0000005459',
      'REGON: 271514909',
      'NIP: 6340135475',
    ],
  },

  delivered: {
    header: 'Oprogramowanie SAIO jest dostarczane przez:',
    lines: [
      'SAIO S.A.',
      'ul. Chorzowska 50, 40-101 Katowice',
      'KRS: 0000995194',
      'REGON: 523302035',
      'NIP: 6343015228',
    ],
  },

  gdpr: {
    header: 'Dane kontaktowe inspektora ochrony danych:',
    lines: [
      'Liliana Rother-Obrączka',
      'gdpr@saio.com',
      'SAIO S.A., ul. Chorzowska 50, 40-101 Katowice',
      'Inspektor Ochrony Danych',
    ],
  },

  privacycontent: [
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Informacje ogólne',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 1,
      content:
        'Niniejsze Oświadczenie o ochronie prywatności („Oświadczenie”) zawiera informacje o przetwarzaniu danych osobowych przez spółkę SAIO S.A. z siedzibą w Katowicach przy ul. Chorzowskiej 50, 40-101, wpisanej do Rejestru Przedsiębiorców w Sądzie Rejonowym Katowice – Wschód VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr. KRS 0000408358, REGON: 242834901, NIP: 6342805313 („Spółka”).',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 2,
      content:
        'Spółka dokłada wszelkich starań, aby zapewnić poufność, bezpieczeństwo i ochronę Państwa danych osobowych podczas ich przetwarzania, zgodnie z obowiązującymi przepisami prawa w zakresie ochrony danych osobowych, w tym z Rozporządzeniem o Ochronie Danych Osobowych („RODO”) (UE) 2016/679 z 27 kwietnia 2016 r.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 3,
      content:
        'Mogą się Państwo skontaktować ze Spółką drogą mailową pod adresem e-mail support@saio.com lub pisząc na adres siedziby Spółki.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 4,
      content:
        'W każdej sprawie dotyczącej przetwarzania danych osobowych, a w szczególności dotyczącej Państwa praw w związku z ochroną danych osobowych, mogą się Państwo skontaktować z Inspektorem Ochrony Danych Spółki drogą mailową pod adresem gdpr@saio.com lub pisząc na adres siedziby Spółki (najlepiej z dopiskiem „RODO”).',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 5,
      content:
        'Spółka może przetwarzać Państwa dane osobowe w sposób określony w Oświadczeniu, jak również podczas zbierania Państwa danych. W związku z tym, niniejsze Oświadczenie powinno być napisane razem z powiadomieniami lub warunkami podanymi podczas zbierania Państwa danych (lub później) lub dostępnymi na innych stronach internetowych Spółki, w tym w Warunkach korzystania z SAIO.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 6,
      content:
        'Podczas korzystania z serwisów internetowych Spółki, Państwa dane osobowe mogą być przetwarzane za pomocą plików cookies, zgodnie z Polityką Plików Cookies, dostępną https://aleo.com/pl_en/about-the-platform/privacy-policy#polityka-cookies',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Kategorie osób, których dane osobowe są przetwarzane przez Spółkę',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: true,
      linenumber: 7,
      content: 'Spółka przetwarza dane osobowe osób fizycznych, które:',
      tablecontent: [],
      subelementscontent: [
        'są użytkownikami SAIO, w tym niezarejestrowanymi użytkownikami („Użytkownicy”);',
        'są przedsiębiorcami, których dane pochodzą ze źródeł publicznych, w tym z rejestrów publicznych (m.in. KRS i CEIDG) („Przedsiębiorcy”);',
        'są potencjalnymi klientami, kontrahentami lub dostawcami Spółki, ponieważ np. podali dane kontaktowe dotyczące usług i produktów oferowanych przez Spółkę („Potencjalni Klienci”);',
        'działają jako osoby uprawnione do reprezentowania osób prawnych lub innych jednostek organizacyjnych („Przedstawiciele”);',
        'uczestniczą we wnioskach, skargach lub innych dokumentach skierowanych do Spółki („Skarżący”);',
        'mogą być adresatami komunikatów marketingowych zgodnie z przepisami prawa.',
      ],
      subalphalist: true,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Cele i podstawa przetwarzania danych osobowych',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 8,
      content:
        'Dane osobowe Użytkowników mogą być przetwarzane przez Spółkę – jako administratora danych – w następujących celach:',
      tablecontent: [
        { header: true, content: ['Cel przetwarzania', 'Podstawa prawna przetwarzania danych'] },
        {
          header: false,
          content: [
            'Zawarcie i wykonanie umowy o korzystanie z SAIO, w tym utworzenie i prowadzenie konta w ramach SAIO, potwierdzenie tożsamości Użytkownika, pobranie opłaty, rozpatrzenie reklamacji, zgłoszenie nadużycia.',
            'Konieczność przetwarzania do zawarcia i wykonania umowy; realizacji obowiązków prawnych ciążących na Spółce, w tym obowiązków wynikających z ustawy o świadczeniu usług drogą elektroniczną.',
          ],
        },
        {
          header: false,
          content: [
            'Obsługa i komunikacja za pośrednictwem stron internetowych, w tym czat, elektroniczne formularze kontaktowe itp.',
            'Realizacja prawnie uzasadnionych interesów Spółki (np. komunikacja z Użytkownikami) oraz dodatkowo zgoda – w przypadku, gdy jest to wymagane (np. przepisy prawa telekomunikacyjnego).',
          ],
        },
        {
          header: false,
          content: [
            'Marketing bezpośredni produktów i usług (otrzymywanie informacji handlowych z różnych kanałów komunikacji).',
            'Realizacja prawnie uzasadnionych interesów Spółki i innych Spółek Grupy ING (przedstawienie oferty ich produktów i usług) oraz dodatkowo umowa – dotycząca elektronicznego i telefonicznego kanału komunikacji.',
          ],
        },
        {
          header: false,
          content: [
            'Dochodzenie odszkodowania i obrona przed roszczeniami związanymi z użytkowaniem SAIO.',
            'Realizacja prawnie uzasadnionych interesów Spółki (dochodzenie roszczeń i obrona przed roszczeniami).',
          ],
        },
        {
          header: false,
          content: [
            'Prowadzenie badań statystycznych, celów analitycznych, badania satysfakcji Użytkownika.',
            'Realizacja prawnie uzasadnionych interesów Spółki dotyczących doskonalenia oferowanych produktów i usług.',
          ],
        },
        {
          header: false,
          content: [
            'Zapewnienie bezpieczeństwa sieci i informacji; przywrócenie gotowości do pracy (np. tworzenie kopii zapasowych).',
            'Realizacja prawnie uzasadnionych interesów Spółki (zapewnienie bezpieczeństwa).',
          ],
        },
        {
          header: false,
          content: [
            'Realizacja obowiązków prawnych wynikających z przepisów prawa polskiego i UE.',
            'Realizacja zobowiązań prawnych wynikających m.in. z przepisów prawa podatkowego i przepisów o rachunkowości.',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 9,
      content:
        'Dane osobowe Przedsiębiorców mogą być przetwarzane przez Spółkę – jako administratora danych – w następujących celach:',
      tablecontent: [
        { header: true, content: ['Cel przetwarzania', 'Podstawa prawna przetwarzania danych'] },
        {
          header: false,
          content: [
            'Cele informacyjne, dotyczące publikowania informacji dotyczących działalności gospodarczej.',
            'Realizacja uzasadnionych interesów Spółki, dotyczących dążenia do osiągnięcia poprawy bezpieczeństwa i poufności transakcji handlowych, weryfikacji wiarygodności podmiotów gospodarczych, publikowania informacji dotyczących działalności gospodarczej, w tym poprzez opinie dodawane na SAIO.',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 10,
      content:
        'Dane osobowe Potencjalnych Klientów mogą być przetwarzane przez Spółkę – jako administratora danych – w następujących celach:',
      tablecontent: [
        { header: true, content: ['Cel przetwarzania', 'Podstawa prawna przetwarzania danych'] },
        {
          header: false,
          content: [
            'Zawarcie umowy o współpracy ze Spółką.',
            'Konieczność przetwarzania danych w celu zawarcia umowy; realizacji obowiązków prawnych ciążących na Spółce, w tym wynikających z ustawy o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu.',
          ],
        },
        {
          header: false,
          content: [
            'Prowadzenie badań statystycznych, cele analityczne.',
            'Realizacja prawnie uzasadnionych interesów Spółki dotyczących doskonalenia oferowanych produktów i usług.',
          ],
        },
        {
          header: false,
          content: [
            'Dochodzenie roszczeń i obrona przed roszczeniami.',
            'Realizacja prawnie uzasadnionych interesów Spółki (dochodzenie roszczeń i obrona przed roszczeniami).',
          ],
        },
        {
          header: false,
          content: [
            'Realizacja obowiązków prawnych wynikających z przepisów prawa polskiego i UE.',
            'Realizacja zobowiązań prawnych wynikających m.in. z przepisów prawa podatkowego i przepisów o rachunkowości.',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 11,
      content:
        'Dane osobowe Klientów mogą być przetwarzane przez Spółkę – jako administratora danych – w następujących celach:',
      tablecontent: [
        { header: true, content: ['Cel przetwarzania', 'Podstawa prawna przetwarzania danych'] },
        {
          header: false,
          content: [
            'Zawarcie umowy ze Spółką, w tym realizacja płatności lub innych transakcji finansowych, rejestracja i rozliczenie finansowe usług, produktów i materiałów dostarczanych dla i od Spółki.',
            'Konieczność przetwarzania danych w celu wykonania umowy; realizacji obowiązków prawnych ciążących na Spółce, w tym wynikających z ustawy o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu.',
          ],
        },
        {
          header: false,
          content: [
            'Marketing bezpośredni produktów i usług (otrzymywanie informacji handlowych z różnych kanałów komunikacji).',
            'Realizacja prawnie uzasadnionych interesów Spółki i innych Spółek Grupy ING (przedstawienie oferty ich produktów i usług) oraz dodatkowo umowa – dotycząca elektronicznego i telefonicznego kanału komunikacji.',
          ],
        },
        {
          header: false,
          content: [
            'Dochodzenie roszczeń i obrona przed roszczeniami.',
            'Realizacja prawnie uzasadnionych interesów Spółki (dochodzenie roszczeń i obrona przed roszczeniami).',
          ],
        },
        {
          header: false,
          content: [
            'Prowadzenie badań statystycznych, cele analityczne.',
            'Realizacja prawnie uzasadnionych interesów Spółki dotyczących doskonalenia oferowanych produktów i usług.',
          ],
        },
        {
          header: false,
          content: [
            'Realizacja obowiązków prawnych wynikających z przepisów prawa polskiego i UE.',
            'Realizacja zobowiązań prawnych wynikających m.in. z przepisów prawa',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: true,
      subelements: false,
      linenumber: 12,
      content:
        'Dane osobowe Przedstawicieli i Skarżących mogą być przetwarzane przez Spółkę – jako administratora danych – w następujących celach:',
      tablecontent: [
        { header: true, content: ['Cel przetwarzania', 'Podstawa prawna przetwarzania danych'] },
        {
          header: false,
          content: [
            'Zawarcie i wykonanie umowy o korzystanie z SAIO, w tym utworzenie i prowadzenie konta w ramach SAIO, potwierdzenie tożsamości Użytkownika, pobranie opłaty, rozpatrzenie reklamacji, zgłoszenie nadużycia.',
            'Konieczność przetwarzania do zawarcia i wykonania umowy; realizacji obowiązków prawnych ciążących na Spółce, w tym obowiązków wynikających z ustawy o świadczeniu usług drogą elektroniczną.',
          ],
        },
        {
          header: false,
          content: [
            'Obsługa i komunikacja za pośrednictwem stron internetowych, w tym czat, elektroniczne formularze kontaktowe itp.',
            'Realizacja prawnie uzasadnionych interesów Spółki (np. komunikacja z Użytkownikami) oraz dodatkowo zgoda – w przypadku, gdy jest to wymagane (np. przepisy prawa telekomunikacyjnego).',
          ],
        },
        {
          header: false,
          content: [
            'Dochodzenie roszczeń i obrona przed roszczeniami.',
            'Realizacja prawnie uzasadnionych interesów Spółki (dochodzenie roszczeń i obrona przed roszczeniami).',
          ],
        },
        {
          header: false,
          content: [
            'Realizacja obowiązków prawnych wynikających z przepisów prawa polskiego i UE.',
            'Realizacja przepisów prawnych wynikających m.in. z przepisów Kodeksu Cywilnego i Kodeksu Postępowania Cywilnego.',
          ],
        },
      ],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 13,
      content:
        'Informacją handlową jest każda forma promocji i oferty handlowej, która dotyczy produktów i usług Spółki oraz produktów i usług spółek z Grupy ING. Informacje handlowe mogą mieć formę spersonalizowanych komunikatów opartych na operacjach profilowania, które udostępniają propozycje produktów lub usług odpowiadających Państwa preferencjom, profilowi działalności gospodarczej (Państwa i podmiotu reprezentowanego przez Państwa), historii zakupów, znanych przewidywalnych oczekiwań.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Jak długo dane osobowe mogą być przechowywane w bazach danych?',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: true,
      linenumber: 14,
      content: 'Okres przechowywania danych osobowych zależy od celów ich przetwarzania i wynosi:',
      tablecontent: [],
      subelementscontent: [
        'w zakresie wykonywania umowy o korzystanie z SAIO / umowy o współpracy ze Spółką oraz dochodzenia roszczeń i obrony przed roszczeniami wynikającymi z umowy – w czasie trwania umowy, a także po jej rozwiązaniu do czasu wygaśnięcia roszczeń z niej wynikających (termin ten wynika z Kodeksu cywilnego);',
        'w zakresie realizacji celów informacyjnych, w zakresie publikacji informacji dotyczących działalności gospodarczej – do czasu wniesienia skargi wynikającej z Państwa szczególnej sytuacji (z wyjątkiem braku uzasadnionej podstawy prawnej do przetwarzania po stronie Spółki);',
        'w zakresie utrzymania i komunikacji za pośrednictwem stron internetowych – na okres 30 dni;',
        'w zakresie załatwiania wniosków, skarg i innych pism kierowanych do Spółki – do czasu zakończenia sprawy dotyczącej wniosku, skargi lub innego pisma kierowanego do Spółki;',
        'w zakresie marketingu bezpośredniego – do czasu złożenia reklamacji lub odstąpienia od umowy;',
        'w zakresie badań statystycznych, działań analitycznych, badań satysfakcji Klientów – w okresie obowiązywania umowy, a po jej zakończeniu wyłącznie w formie zanonimizowanej lub zagregowanej (dane statystyczne);',
        'w zakresie realizacji obowiązków prawnych – do czasu wygaśnięcia dokumentu (np. dokumenty księgowe, w tym zawarte w nich dane, wymagają przechowywania przez okres 5 lat).',
      ],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Komu można przekazywać dane osobowe?',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: true,
      linenumber: 15,
      content: 'Państwa dane osobowe mogą być przekazywane do następujących podmiotów:',
      tablecontent: [],
      subelementscontent: [
        'innym spółkom Grupy ING, wymienionym na następnej stronie https://aleo.com/pl_en/about-the-platform/ing-group - do wewnętrznych celów administracyjnych, np. w zakresie sprawozdawczości w ramach Grupy ING, dla celów statystycznych, księgowych i zarządczych, w tym wewnętrznej analizy zarządczej (np. w zakresie realizacji wymogów wynikających z przeciwdziałania praniu pieniędzy i finansowaniu terroryzmu);',
        'innym spółkom Grupy ING – dla ich własnych celów marketingowych (w przypadku wyrażenia określonej zgody);',
        'podmiotom działającym na podstawie przepisów prawa (organy władzy publicznej), w tym Prokuraturze, Policji i Urzędowi Skarbowemu – w zakresie realizacji obowiązków prawnych ciążących na Spółce (np. w zakresie ewentualnych nadużyć na SAIO);',
        'podmiotom świadczącym usługi marketingowe i reklamowe na rzecz Spółki lub innych spółek z Grupy ING (np. agencje marketingowe, agencje interaktywne) – w zakresie niezbędnym do realizacji tych usług;',
        'podmiotom świadczącym usługi techniczne na rzecz Spółki lub innych spółek z Grupy ING (np. firmy hostingowe) – w zakresie niezbędnym do realizacji tych usług;',
        'podmiotom świadczącym innego rodzaju usługi na rzecz Spółki lub innych spółek z Grupy ING, np. dostawcy usług prawnych i doradczych – w zakresie świadczenia tych usług.',
      ],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Przekazywanie danych osobowych poza Europejski Obszar Gospodarczy',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 16,
      content:
        'Państwa dane osobowe mogą być przekazywane podmiotom z Grupy ING, w skład której wchodzi Spółka, jak również podmiotom świadczącym określone usługi na rzecz Spółki lub innych spółek z Grupy ING, w szczególności do Stanów Zjednoczonych Ameryki Północnej. Spółki te nie zostały uznane przez Komisję Europejską za kraje zapewniające odpowiedni poziom ochrony danych osobowych.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 17,
      content:
        'Przekazanie danych osobowych do podmiotów Grupy ING dotyczy Wiążących Zasad Korporacyjnych, zatwierdzonych przez właściwy organ holenderski kontrolujący przestrzeganie przepisów o ochronie danych osobowych.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 18,
      content:
        'Mogą Państwo otrzymać kopię Wiążących Zasad Korporacyjnych – w tym celu prosimy o kontakt z Inspektorem Ochrony Danych.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Jak chronimy Państwa dane osobowe?',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 19,
      content:
        'Jesteśmy świadomi, że Państwa dane osobowe są poufne. Spółka dba o poufność, bezpieczeństwo i chroni Państwa dane osobowe zgodnie z obowiązującym prawem, w tym z przepisami RODO, a także wewnętrznymi politykami i instrukcjami stosowanymi przez Spółkę.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 20,
      content:
        'Spółka wdrożyła technologiczne i operacyjne środki bezpieczeństwa w celu ochrony danych osobowych przed utratą, nadużyciem lub nieautoryzowaną modyfikacją lub zniszczeniem. Te formy ochrony obejmują m.in. użytkowanie, szyfrowanie, odpowiednie procesy zarządzania dotyczące poziomów dostępu lub inne środki techniczne i organizacyjne zapewniające właściwą ochronę Państwa danych osobowych. Środki te zapewniają odpowiedni poziom bezpieczeństwa w zakresie zagrożeń o charakterze danych osobowych będących przedmiotem ochrony.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 21,
      content:
        'Mamy jednak świadomość, że w przypadku przesyłania informacji przez Internet nie ma gwarancji, że są one w 100% bezpieczne.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 22,
      content:
        'Korzystamy z bezpiecznego systemu płatności online dla każdej płatności, którą pobieramy od Państwa online.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Czy podanie danych osobowych jest obowiązkowe?',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 23,
      content:
        'Korzystanie z SAIO, jak również współpraca i kontakt z Spółką są dobrowolne. Podanie danych osobowych w zakresie rejestracji na SAIO, jak również zawarcie umowy o współpracy jest jednak niezbędne do zawarcia umowy i jej wykonania – bez podania danych osobowych jest to niemożliwe.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 24,
      content:
        'Podanie danych osobowych dla celów marketingu bezpośredniego jest dobrowolne – w szczególności nie warunkuje zawarcia i wykonania umowy o korzystanie z SAIO.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: true,
      table: false,
      subelements: false,
      linenumber: 0,
      content: 'Prawa dotyczące przetwarzania danych osobowych',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: true,
      linenumber: 25,
      content:
        'W związku z przetwarzaniem danych osobowych przysługują Państwu prawa – na podstawie przepisów o ochronie danych osobowych, w tym przepisów RODO – w tym:',
      tablecontent: [],
      subelementscontent: [
        'prawo dostępu do danych osobowych dotyczących Państwa – mają Państwo prawo do otrzymania od Spółki potwierdzenia na adres informacji o tym, czy Państwa dane osobowe są przetwarzane przez Spółkę, a także innych określonych informacji (np. dotyczących celów przetwarzania, a także kategorii przetwarzanych danych osobowych). Mają Państwo również prawo dostępu do swoich danych osobowych, w postaci kopii danych osobowych, które Państwa dotyczą. Ma to na celu zapewnienie, że użytkownik jest w pełni świadomy i może sprawdzić, w jaki sposób Spółka wykorzystuje jego dane osobowe. Spółka może odmówić udostępnienia Państwu kopii danych osobowych w przypadku możliwości negatywnego wpływu na prawa innej osoby;',
        'prawo do sprostowania danych – przysługuje Państwu prawo do żądania niezwłocznego sprostowania danych osobowych, w każdym przypadku, gdy są one nieprawidłowe lub niekompletne (np. Spółka przetwarza nieprawidłowe imię, nazwisko lub adres); w takim przypadku mogą Państwo złożyć stosowne oświadczenie;',
        'prawo do usunięcia danych (znane również jako „prawo do bycia zapomnianym”) – udostępnia wniosek o usunięcie Państwa danych osobowych, gdy np. dane zostały wykorzystane niezgodnie z prawem lub gdy rozwiązano z Państwem umowę (gdy była ona jedyną podstawą przetwarzania Państwa danych). „Prawo do bycia zapomnianym” nie jest bezwzględnym prawem do usunięcia Państwa danych osobowych, ponieważ istnieją pewne wyjątki, np. gdy Spółka nadal potrzebuje tych danych do ustalenia, dochodzenia lub obrony roszczeń lub w celu wypełnienia obowiązku prawnego;',
        'prawo do ograniczenia przetwarzania danych – mają Państwo prawo powstrzymać Spółkę od dalszego wykorzystywania Państwa danych osobowych, gdy np. Spółka zażąda ich poprawienia. W przypadku ograniczenia przetwarzania danych osobowych, Spółka może przechowywać dane osobowe, ale nie może ich już aktywnie wykorzystywać (np. w celu realizacji umowy);',
        'prawo do przenoszenia danych – mają Państwo prawo otrzymać i ponownie wykorzystać wskazane dane osobowe dla własnych potrzeb innych podmiotów (będących niezależnymi administratorami danych). Prawo to można zastosować wyłącznie w odniesieniu do Państwa danych osobowych, które nam Państwo udostępnili i które przetwarzamy (w sposób zautomatyzowany) za Państwa zgodą lub w celu wykonania umowy. W takim przypadku przekażemy Państwu kopię Państwa danych osobowych w ustrukturyzowanym, powszechnie używanym i możliwym do odczytu maszynowego formacie lub (jeżeli będzie to technicznie możliwe), możemy przesłać Państwa dane osobowe bezpośrednio do innego administratora danych. Spółka może odmówić realizacji tych praw, jeżeli mogłoby to negatywnie wpłynąć na prawa innych osób.',
      ],
      subalphalist: true,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 26,
      content:
        'W zakresie, w którym podstawą przetwarzania Państwa danych osobowych jest realizacja prawnie uzasadnionych interesów przysługuje Państwu prawo wniesienia skargi na przetwarzanie tych danych, w szczególności w zakresie ich przetwarzania dla celów marketingu bezpośredniego. Spółka może jednak kontynuować przetwarzanie danych osobowych w przypadku możliwości wykazania ważnych i prawnie obowiązujących podstaw przetwarzania, które mają charakter nadrzędny wobec Państwa interesów, praw lub wolności, lub jeśli jest to niezbędne do dochodzenia lub obrony roszczeń. Jeśli nie zgadzają się Państwo na przetwarzanie Państwa danych dla celów marketingu bezpośredniego, nie będziemy dalej przetwarzać Państwa danych osobowych dla takich celów.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 27,
      content:
        'W zakresie, w którym podstawą przetwarzania Państwa danych osobowych jest Państwa zgoda, mają Państwo prawo do jej odwołania w dowolnym momencie. Odstąpienie od umowy nie ma wpływu na zgodność przetwarzania, którego dokonano na podstawie umowy przed jej odstąpieniem.',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
    {
      header: false,
      table: false,
      subelements: false,
      linenumber: 28,
      content:
        'Przysługuje Państwu prawo wniesienia skargi do organu kontrolującego przestrzeganie tego obowiązku (Szef Urzędu Ochrony Danych Osobowych).',
      tablecontent: [],
      subelementscontent: [],
      subalphalist: false,
    },
  ],

  cookiecontent: [
    {
      subelements: false,
      content:
        'SAIO nie zbiera w sposób automatyczny informacji, z wyjątkiem informacji zawartych w plikach cookies.',
      subelementscontent: [],
    },
    {
      subelements: false,
      content:
        'Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Platformy Ale („Użytkownik”) i przeznaczone są do korzystania ze stron internetowych SAIO. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.',
      subelementscontent: [],
    },
    {
      subelements: false,
      content:
        'Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika pliki cookies oraz uzyskującym do nich dostęp jest Spółka, jako operator SAIO.',
      subelementscontent: [],
    },
    {
      subelements: true,
      content: 'Pliki cookies są używane do:',
      subelementscontent: [
        'dostosowania zawartości stron internetowych SAIO do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te umożliwiają wykrycie urządzenia Użytkownika i odpowiednie wyświetlenie strony internetowej, dostosowanej do jego indywidualnych potrzeb;',
        'tworzenia statystyk, które ułatwiają zrozumienie, w jaki sposób Użytkownicy korzystają z serwisów internetowych, co umożliwia ulepszanie ich struktury i zawartości;',
        'utrzymania sesji Użytkownika (po zalogowaniu), tak aby nie musiał on wpisywać loginu i hasła na każdej stronie w ramach SAIO.',
      ],
    },
    {
      subelements: false,
      content:
        'W ramach SAIO stosowane są dwa rodzaje plików cookies: „sesyjne” i „trwałe” pliki cookies. Sesyjne pliki cookies są plikami tymczasowymi, które przechowywane są na urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub zamknięcia przeglądarki. Trwałe pliki cookies przechowywane są na urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.',
      subelementscontent: [],
    },
    {
      subelements: true,
      content: 'W ramach SAIO stosowane są następujące rodzaje plików cookies:',
      subelementscontent: [
        '„niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach SAIO, np. uwierzytelniające pliki cookies wykorzystywane w usługach wymagających uwierzytelnienia w ramach SAIO;',
        'pliki cookies wykorzystywane do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach SAIO;',
        'Pliki cookies „wydajność” / „usługa”, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych SAIO, w tym korzystanie z narzędzia Google Analytics udostępnianego przez firmę Google (której Spółka powierzyła przetwarzanie danych osobowych w tych celach);',
        '„funkcjonalne” pliki cookies, umożliwiające „zapamiętywanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu Użytkownika, rozmiaru czcionki, układu strony internetowej itp.;',
        '„marketingowe” pliki cookies, umożliwiające dostarczanie treści marketingowych bardziej dostosowanych do zainteresowań Użytkowników.',
      ],
    },
    {
      subelements: false,
      content:
        'W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka) domyślnie umożliwia przechowywanie plików cookies na urządzeniu końcowym Użytkownika. Użytkownicy SAIO mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. W szczególności ustawienia te mogą zostać zmienione w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować Użytkownika o ich każdorazowym zamieszczeniu na urządzeniu Użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).',
      subelementscontent: [],
    },
    {
      subelements: false,
      content:
        'Spółka informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych w ramach SAIO.',
      subelementscontent: [],
    },
  ],
};

export default messages;
