
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class WantKnowMore extends Vue {
  @Prop({ required: true }) nextPageRoute!: string;

  @Prop({ required: true }) nextPageTitle!: string;

  @Prop({ required: true }) isHeaderVisible!: boolean;

  @Prop({ default: true }) isContactVisible!: boolean;
}
