const messages = {
  header: 'Porozmawiajmy o SAIO',
  name: 'Imię i Nazwisko',
  email: 'Email',
  message: 'Wiadomość',
  gdpr: 'Oświadczam w imieniu Firmy, że jest ona zainteresowana usługami świadczonymi przez SAIO S.A. z siedzibą w Katowicach, ul. Chorzowska 50, 40-101 Katowice (Spółka), w tym otrzymywaniem od Spółki  informacji handlowej. W związku z powyższym wyrażam zgodę na przetwarzanie przez Spółkę moich danych osobowych podanych w niniejszym formularzu wyłącznie w celu prezentacji produktów i usług oferowanych przez Spółkę. Niniejsza zgoda jest dobrowolna i ważna do czasu jej odwołania, a jeśli nie zostanie odwołana, do 24 miesięcy od miesiąca, w którym zgoda została udzielona.',
  sendButton: 'Wyślij',
};

export default messages;
