import storeBuilder from '@/store/storeBuilder';
import { AppState } from './states';
import { App } from './types';

const b = storeBuilder.module<AppState>('appState', new AppState());

// getters
const appState = b.read((state) => state.appState, 'appState');

export const getters = {
  get getAppState() {
    return appState();
  },
};

// mutations
function setAppState(state: AppState, newState: App) {
  state.appState = {
    currentLanguage: newState.currentLanguage,
  };
}

export const mutations = {
  appState: b.commit(setAppState),
};
