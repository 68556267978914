const messages = {
  title: 'Zostań partnerem',
  header:
    'Dołącz SAIO do oferty i wprowadź swoich klientów na wyższy poziom technologii. Wybór nas dla twoich klientów zapewni tobie:',

  consulting: [
    {
      header: 'Innowacyjny produkt',
      description1: '…który może stać się wizytówką Twojej nowoczesnej firmy.',
      description2: '',
    },
    {
      header: 'Technologia, która wspiera relacje',
      description1: 'Oferując SAIO, dajesz swoim klientom efektywne  i sprawdzone rozwiązanie.',
      description2: '',
    },
    {
      header: 'Zespół, który inspiruje',
      description1:
        'Dodając SAIO do swojej oferty, możesz liczyć na wsparcie i inspiracje od naszych ekspertów.',
      description2: '',
    },
  ],

  leaveContactButton: 'Zostaw kontakt',
};

export default messages;
