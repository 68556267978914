const messages = {
  header: "Let's talk about SAIO",
  name: 'Name',
  email: 'Email',
  message: 'Message',
  gdpr: 'On behalf of the Company, I certify that it is interested in the services provided by ING Usługi dla Biznesu S.A. with headquarters in Katowice, ul. Chorzowska 50, 40-121 Katowice (the Company), including receiving commercial information from the Company. In connection with the above, I consent to the processing by the Company of my personal data provided in this form solely for the purpose of presenting products and services offered by the Company. This consent is voluntary and valid until revoked, and if not revoked, up to 24 months from the month in which the consent was given.',
  sendButton: 'Send',
};

export default messages;
