import { RouteConfig } from 'vue-router';
import Home from '@/views/home/Home.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    pathToRegexpOptions: { strict: false },
    props: {
      isHidden: true,
    },
    meta: {
      isHiddenHeaderLogo: true,
      useTransparentMenuBackground: true,
    },
  },
  {
    path: '/why-saio',
    name: 'why-saio',
    component: () => import('@/views/why-saio/WhySaio.vue'),
    pathToRegexpOptions: { strict: false },
  },
  // {
  //   path: '/implementations',
  //   name: 'implementations',
  //   component: () => import('@/views/implementations/Implementations.vue'),
  //   pathToRegexpOptions: { strict: false },
  // },
  // {
  //   path: '/implementations/implementation/:id',
  //   name: 'implementation',
  //   component: () => import('@/views/implementations/Implementation.vue'),
  //   pathToRegexpOptions: { strict: false },
  // },
  {
    path: '/contact-partnership',
    name: 'contact',
    component: () => import('@/views/contact/Contact.vue'),
    meta: {
      isHiddenHeaderLogo: false,
      useTransparentMenuBackground: true,
    },
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/cooperation',
    name: 'become-client',
    component: () => import('@/views/cooperation/CommonBecome.vue'),
    meta: {
      isFooterHidden: true,
    },
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/cooperation',
    name: 'become-partner',
    component: () => import('@/views/cooperation/CommonBecome.vue'),
    meta: {
      isFooterHidden: true,
    },
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/about-saio',
    name: 'about-saio',
    component: () => import('@/views/about-saio/AboutSaio.vue'),
    meta: {
      isGoRobotGoFooter: true,
    },
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/technology',
    name: 'technology',
    component: () => import('@/views/technology/Technology.vue'),
    meta: {
      showFooterTopBorder: true,
    },
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/newsroom',
    name: 'newsroom',
    component: () => import('@/views/newsroom/Newsroom.vue'),
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/newsroom/news/:id',
    name: 'news-post',
    component: () => import('@/views/newsroom/NewsPost.vue'),
    meta: {
      isGoRobotGoFooter: true,
    },
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/contact',
    name: 'contact-form',
    component: () => import('@/views/contact-form/ContactForm.vue'),
    meta: {
      isFooterHidden: true,
    },
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('@/views/legal/Legal.vue'),
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('@/views/notfound/Notfound.vue'),
    meta: {
      useTransparentMenuBackground: true,
      isFooterHidden: true,
    },
    pathToRegexpOptions: { strict: false },
  },
  {
    path: '/:catchAll(.*)',
    meta: {
      treatAsPageNotFound: true,
    },
    pathToRegexpOptions: { strict: false },
  },
];

export default routes;
