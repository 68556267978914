import about from './about';
import beClient from './beClient';
import bePartner from './bePartner';
import contact from './contact';
import footer from './footer';
import home from './home';
import contactModal from './contactModal';
import clients from './clients';
import implementations from './implementations';
import saio from './saio';
import whySaio from './whySaio';
import technology from './technology';
import menu from './menu';
import contactForm from './contactForm';
import newsroom from './newsroom';
import genericForm from './genericForm';
import cookieConsent from './cookieConsent';
import legal from './legal';
import wantKnowMore from './wantKnowMore';
import notfound from './notfound';

const config = {
  about,
  beClient,
  bePartner,
  contact,
  home,
  footer,
  contactModal,
  clients,
  implementations,
  saio,
  whySaio,
  technology,
  menu,
  contactForm,
  newsroom,
  genericForm,
  cookieConsent,
  legal,
  wantKnowMore,
  notfound,
};

export default config;
