
import { Component, Vue } from "vue-property-decorator";
import NewsSnippet from "@/components/NewsSnippet.vue";
import Claims from "@/components/Claims.vue";
import Clients from "@/components/Clients.vue";
import WantKnowMore from "@/components/WantKnowMore.vue";
import ImageSectionPeople from "@/components/sections/image/ImageSectionPeople.vue";
import isMobile from "@/services/mobileService";
import AboutRobotisation from "@/components/AboutRobotisation.vue";
import TimeTabs from "./TimeTabs.vue";

@Component({
  components: {
    NewsSnippet,
    Claims,
    Clients,
    WantKnowMore,
    ImageSectionPeople,
    TimeTabs,
    AboutRobotisation,
  },
})
export default class Home extends Vue {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  public nextPage = "why-saio";

  mounted() {
    
    (window as any).jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0,
      videoSrc: "mp4:./animations/Ambient_main_page.mp4",
      videoLazyLoading: true,
      videoLoop:true
    });

    const elem = document.getElementsByClassName("home-header-animation")[0];
    if (!this.canShowLongAnimation) {
      elem.classList.add("home-header-animation-small");
    }

    const lottie = require("lottie-web/build/player/lottie_light.min");

    const animation = lottie.loadAnimation({
      container: this.$refs.sayhitosaio as Element,
      renderer: "svg",
      autoplay: true,
      path: this.canShowLongAnimation
        ? "animations/SayHiToAiAnimation_long.json"
        : "animations/SayHiToAiAnimation_square.json",
    });
    animation.addEventListener("enterFrame", (animationPlayer: any) => {
      if (animationPlayer.currentTime > 94) {
        animation.pause();
      }
    });
  }

  get canShowLongAnimation() {
    const minimalWidthForLongAnimation = 768;
    return !isMobile() && window.innerWidth >= minimalWidthForLongAnimation;
  }
}
